<ng-template #modal let-modalRef>
    <div class="modal-header">
        <p class="modal-title" ngbAutofocus>
            <b>{{'doctype.clone_doctype' | translate}}</b>
        </p>
    </div>
    <div class="modal-body">
        <div class="form-group row">
            <label class="col-3 col-form-label">{{'doctype.add_edit.client' | translate}}</label>
            <div class="col-9">
                <select class="form-control" (ngModelChange)="onClientChanged()" [(ngModel)]="selectedClient">
                    <option *ngFor="let client of clientsHasDocType"
                            [ngValue]="client">
                        {{client.name}}
                    </option>
                </select>
            </div>
        </div>
        <div class="form-group row" *ngIf="selectedClient">
            <label class="col-3 col-form-label">{{'doctype.doctype' | translate}}</label>
            <div class="col-9">
                <select class="form-control" [(ngModel)]="clonedDoctype">
                    <option *ngFor="let doctype of selectedClient.docTypes"
                            [ngValue]="doctype">
                        {{doctype.displayName}}
                    </option>
                </select>
            </div>
        </div>
        <p class="error"
           *ngIf="(attributesLimit && clonedDoctype) && ( attributesLimit < clonedDoctype.attributes.length)">
            {{'doctype.add_edit.clone_attributes_limit_message' | translate:{
            limit: attributesLimit,
            length: clonedDoctype.attributes.length
        } }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" (click)="modalRef.close()">{{'modal.cancel' | translate}}</button>
        <button type="button" class="btn btn-primary"
                (click)="onSave()">{{'modal.apply' | translate}}</button>
    </div>
</ng-template>
