import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {forkJoin, merge, Observable} from 'rxjs';
import {ClientModel} from '../../models/ClientModel';
import {DoctypeModel} from '../../models/DoctypeModel';
import {map} from 'rxjs/operators';
import {ClientDatabaseService} from '../../services/client-database.service';


interface ReturnData {
  clients: ClientModel[],
  docTypes: ClientModel[]
}

@Injectable()
export class DoctypesResolver implements Resolve<ReturnData> {

  constructor(private clientRepository: ClientDatabaseService) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ReturnData> | Promise<ReturnData> | ReturnData {
    let $clients = this.clientRepository.getList('name', true);
    let $clientsWithDoctypes = $clients.pipe(map(data => {
      return data.filter((client: ClientModel) => {
        if (!!client.docTypes && client.docTypes.length > 0) {
          client.docTypes.forEach((docType: DoctypeModel) => {
            docType.client = client._id;
          });
          return true;
        }
        return false;
      });
    }));
    return forkJoin([$clients, $clientsWithDoctypes]).pipe(map(data => ({
      clients: data[0],
      docTypes: data[1]
    })));
  }

}
