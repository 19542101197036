import {Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild} from "@angular/core";
import {ClientModel} from "../../../models/ClientModel";
import {ClientDatabaseService} from "../../../services/client-database.service";
import {CustomerDatabaseService} from "../../../services/customer-database.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {CustomerModel} from "../../../models/CustomerModel";
import {TranslateService} from "@ngx-translate/core";
import {Observable, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-delete-modal',
  templateUrl: './app-delete-modal.html'
})
export class AppDeleteModal implements OnDestroy {
  @ViewChild('deleteModal') modal: ElementRef;
  @Output('onSuccess') onSuccess: EventEmitter<void> = new EventEmitter();

  client: ClientModel;
  customer: CustomerModel;
  modalWindow: NgbModalRef;
  isDeleteClient: boolean;
  private unsubscribe: Subject<void> = new Subject();

  constructor(
    protected clientRepository: ClientDatabaseService,
    protected customerRepository: CustomerDatabaseService,
    protected modalService: NgbModal,
    protected translate: TranslateService
  ) {

  }

  onShowModal(customer: CustomerModel, client: ClientModel) {
    this.customer = customer;
    this.client = client;
    this.isDeleteClient = (this.customer['clients'].length > 1);
    this.modalWindow = this.modalService.open(this.modal);
  }

  onDelete() {
    if (this.isDeleteClient) {
      // Delete client
      let clientIndex = -1;
      this.customer.clients.forEach((client: ClientModel, index: number) => {
        if (clientIndex >= 0)
          return;
        if (client._id == this.client._id)
          clientIndex = index;

      });
      if (clientIndex >= 0)
        this.customer.clients.splice(clientIndex, 1);
      this.updateCustomer(this.customer)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe(res => {
        this.clientRepository.delete(this.client._id)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((data) => {
          this.modalWindow.close();
          this.onSuccess.emit();
        });
      });
    } else {
      // Delete Customer
      this.clientRepository.delete(this.client._id)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((data) => {
        this.customerRepository.delete(this.customer._id)
          .pipe(takeUntil(this.unsubscribe))
          .subscribe((data) => {
          this.modalWindow.close();
          this.onSuccess.emit();
        });
      });
    }
  }

  private updateCustomer(customer: CustomerModel): Observable<any> {
    return this.customerRepository.update(customer._id, customer);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
