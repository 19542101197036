<nav id="topNav" class="navbar navbar-expand-xl fixed-top">
    <div class="container no-max-width">
        <a class="navbar-brand page-scroll mr-3" routerLink="">
            <img width="250px" src="../assets/img/netfira_logo_primary.svg" style="margin-top: 4px" />
        </a>
        <div class="collapse navbar-collapse" id="collapsingNavbar" [ngbCollapse]="isCollapsed">
            <ul class="navbar-nav mr-auto" *ngIf="isAuth">
                <li class="nav-item active">
                    <a class="nav-link" routerLink="/clients" routerLinkActive="active">{{ 'navbar.clients' | translate }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/doctypes" routerLinkActive="active">{{ 'navbar.doctypes' | translate }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/mail-templates" routerLinkActive="active">{{ 'navbar.mailTemplates' | translate }}</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" routerLink="/onboardings-list" routerLinkActive="active">Onboardings</a>
                </li>
            </ul>
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a class="nav-link nav-link-custom page-scroll" (click)="onChangeLang()">ENG/DE</a>
                </li>
            </ul>
            <ul class="navbar-nav" *ngIf="isAuth">
                <li class="nav-item">
                    <a class="nav-link page-scroll log-out-btn" (click)="logout()">{{ 'navbar.logout' | translate }}</a>
                </li>
            </ul>
        </div>
        <button
            class="navbar-toggler"
            type="button"
            (click)="isCollapsed = !isCollapsed"
            [attr.aria-expanded]="!isCollapsed"
            aria-controls="collapsingNavbar"
            data-toggle="collapse"
            data-target="#collapsingNavbar"
            aria-expanded="false"
            aria-label="Toggle navigation"
        >
            ☰
        </button>
    </div>
</nav>
