import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { OnboardingsListService } from '../../services/onboardings-list.service';
import { TicketDescriptionModalComponent } from './ticket-description-modal/ticket-description-modal.component';

@Component({
    selector: 'app-onboardings-list',
    templateUrl: './onboardings-list.component.html',
    styleUrls: ['./onboardings-list.component.scss']
})

export class OnboardingsListComponent implements OnInit {

    private ticketDescriptionModal: NgbModalRef;

    displayedColumns: string[] = ['data', 'extra'];
    tickets = [];
    currentPage = 1;
	limit = 15;
	totalPages: number;
    showOnlyErrors = false;
    locale;

    constructor(        
        private onboardingsService: OnboardingsListService,
        public translateService: TranslateService,
        private _modalService: NgbModal) { }

    ngOnInit(): void {
        this.showOnlyErrors = false;

        this.setLocale();
        this.translateService.onLangChange.subscribe(() => {
            this.setLocale();
        });

        this.getData();
    }

    setLocale() {
        this.locale = this.translateService.currentLang === 'de' ? 'de_DE' : 'en-US';
    }

    getData(){
        this.onboardingsService.loadOnboardings((this.currentPage - 1) * this.limit, this.limit).subscribe(({ tickets, count }) => {
            this.totalPages = Math.ceil(count / this.limit);
            this.tickets = tickets;
        });
    }

    onPagingChanged($event) {
		this.currentPage = $event;
		this.getData();
	}

    showTicketDescriptionModal(ticketDescription): void {
		this.ticketDescriptionModal = this._modalService.open(TicketDescriptionModalComponent, {
			size: 'lg',
			windowClass: 'ticket-description-modal'
		});
		this.ticketDescriptionModal.componentInstance.ticketDescription = ticketDescription;
	}

    toggleErrorsOnly(): void {
		this.showOnlyErrors = !this.showOnlyErrors;
	}
}
