import {Injectable} from '@angular/core';
import {ApiRoutesService} from './api-routes.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PortalService {

  constructor(
    protected routeService: ApiRoutesService,
    protected http: HttpClient) {
  }

  testPortalUrl(portalUrl: string, token?: string): Observable<any> {
    const url = this.routeService.PORTAL;
    const params = {
      portal: portalUrl
    };
    if (token && token != '') {
      params['portalToken'] = token;
    }
    return this.http.get(url, {params: params});
  }

  checkPortalAuthorization(portalUrl: string, token: string) {
    const data = {
      portal: portalUrl,
      portalToken: token
    };
    return this.http.post(this.routeService.PORTAL + '/login', data);
  }

}
