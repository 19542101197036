import {Component, EventEmitter, Input, OnInit, Output, NgModule} from '@angular/core';

// Component uses in Onboarding Module
@Component({
  selector: 'app-paging',
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss']
})
export class PagingComponent implements OnInit {

  currentPage: number;
  totalPages: number;

  @Input('currentPage') set setCurrentPage(cp) {
    this.currentPage = cp;
  }

  @Input('totalPages') set setTotalPages(tp) {
    this.totalPages = tp;
    this.generateLinks();
  }

  @Output() $onPagingChanged: EventEmitter<number> = new EventEmitter<number>();

  links = [];
  totalLinks = 3;


  onPageChanged(page: number) {
    if (this.currentPage == page) {
      return;
    }
    this.currentPage = page;
    this.$onPagingChanged.emit(page);
    this.generateLinks();
  }

  ngOnInit(): void {
    this.generateLinks();
  }

  generateLinks() {
    this.links = [];
    if (this.totalPages >= this.totalLinks) {
      // If first page
      if (this.currentPage == 1) {
        for (let i = 1; i <= this.totalLinks; i++) {
          this.links.push({page: i, active: i == this.currentPage});
        }
        // If last page
      } else if (this.currentPage == this.totalPages) {
        let start = this.currentPage - this.totalLinks;
        for (let i = start + 1; i <= this.totalPages; i++) {
          this.links.push({page: i, active: i == this.currentPage});
        }
        // If not first or last page was selected.
      } else {
        for (let i = this.currentPage - 1; i <= this.currentPage + 1; i++) {
          this.links.push({page: i, active: i == this.currentPage});
        }
      }
    } else {
      // If Total pages less then total links.
      for (let i = 1; i <= this.totalPages; i++) {
        this.links.push({page: i, active: i == this.currentPage});
      }
    }
  }
}
