<label class="text-center error col-12 col-form-label"
    >{{ 'errors.mail_template_warning_2' | translate }}
</label>
<div class="text-center">
    <button class="btn btn-primary add-new" (click)="onAddMailTemplate()">{{ 'mail_template.add_new' | translate }}</button>
</div>
<div class="table-wrap responsive-table-block">
    <table class="table responsive-table overview-table sorting-table" *ngIf="mailTemplateList">
        <thead>
            <tr>
                <th class="has-sorting" [ngClass]="getSortClass(sortingList.name)">
                    <span (click)="onSort(sortingList.name)">{{ 'global.name' | translate }}</span>
                </th>
                <th>{{ 'global.template' | translate }}</th>
                <th></th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let template of mailTemplateList">
                <td class="name">{{ template.displayName }}</td>
                <td class="template"><span [innerHTML]="template.template"></span></td>
                <td class="action">
                    <span class="pointer action-link" title="{{ 'options.edit' | translate }}" (click)="onEditMailTemplate(template)">
                        <i class="fa fa-pencil"></i>
                    </span>
                </td>
            </tr>
        </tbody>
    </table>
</div>
<app-edit-mail-template-modal #editModalTemplate (onUpdateMailTemplate)="ouUpdateMailTemplate($event)"></app-edit-mail-template-modal>
