<ng-template #modal let-modalRef>
    <div class="modal-header">
        <p class="modal-title" ngbAutofocus>
            <b *ngIf="!isEdit">{{ 'doctype.add_edit.create_doctype' | translate }}</b>
            <b *ngIf="isEdit">{{ 'doctype.add_edit.edit_doctype' | translate }}</b>
        </p>
    </div>
    <div class="modal-body">
        <div [formGroup]="docTypeForm" *ngIf="availableDoctypes && availableDoctypes.length > 0">
            <div *ngIf="!isEdit">
                <p>
                    <button class="btn btn-success" id="clone-doctype" (click)="onShowCloneDoctypeModal()">{{ 'doctype.clone_doctype' | translate }}</button>
                    &nbsp;
                    <button class="btn btn-primary" *ngIf="cloneDoctype" id="clear-clone" (click)="onClearClone()">{{ 'doctype.clone_clear' | translate }}</button>
                </p>
                <div class="form-group row">
                    <label class="col-3 col-form-label">{{ 'doctype.add_edit.clone_attributes' | translate }}</label>
                    <div class="col-9">
                        <select class="form-control" formControlName="cloneAttributesDocTypeId">
                            <option [value]="">Please select</option>
                            <option *ngFor="let docType of docTypesList" [value]="docType._id">{{ docType.name }} ({{ findClientById(docType.client).name }})</option>
                        </select>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-3 col-form-label">{{ 'doctype.add_edit.client' | translate }}</label>
                    <div class="col-9">
                        <select class="form-control" formControlName="clientId">
                            <option *ngFor="let client of clientsList" [ngValue]="client._id">
                                {{ client.name }}
                            </option>
                        </select>
                        <div class="error" *ngIf="docTypeForm.get('clientId').touched && docTypeForm.get('clientId').invalid">
                            <p *ngIf="docTypeForm.get('clientId').errors.required">
                                {{ 'errors.errors_require' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-group row" *ngIf="isEdit">
                <label class="col-3 col-form-label">{{ 'doctype.add_edit.client' | translate }}</label>
                <div class="col-9">
                    <input class="form-control" type="text" [value]="findClientById(docType.client).name" disabled />
                </div>
            </div>
            <div class="form-group row">
                <label class="col-3 col-form-label">{{ 'doctype.add_edit.name' | translate }}</label>
                <div class="col-9">
                    <input *ngIf="cloneDoctype" class="form-control" type="text" formControlName="name" />
                    <select *ngIf="!cloneDoctype" (change)="onSelectVersions($event.target.value)" class="form-control custom-select" formControlName="name">
                        <option *ngFor="let dType of availableDoctypes" [value]="dType.type">
                            {{ dType.type }}
                        </option>
                    </select>
                    <div class="error" *ngIf="docTypeForm.get('name').touched && docTypeForm.get('name').invalid">
                        <p *ngIf="docTypeForm.get('name').errors.required">
                            {{ 'errors.errors_require' | translate }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-3 col-form-label">{{ 'doctype.add_edit.display_name' | translate }}</label>
                <div class="col-9">
                    <input class="form-control" type="text" formControlName="displayName" />
                    <div class="error" *ngIf="docTypeForm.get('displayName').touched && docTypeForm.get('displayName').invalid">
                        <p *ngIf="docTypeForm.get('displayName').errors.required">
                            {{ 'errors.errors_require' | translate }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-3 col-form-label">{{ 'doctype.add_edit.version' | translate }}</label>
                <div class="col-9">
                    <input *ngIf="cloneDoctype" class="form-control" type="number" formControlName="version" />
                    <select *ngIf="!cloneDoctype" class="form-control custom-select" formControlName="version">
                        <option *ngFor="let version of selectedDoctype.versions" [ngValue]="version">
                            {{ version }}
                        </option>
                    </select>
                    <div class="error" *ngIf="docTypeForm.get('version').touched && docTypeForm.get('version').invalid">
                        <p *ngIf="docTypeForm.get('version').errors.required">
                            {{ 'errors.errors_require' | translate }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-3 col-form-label">{{ 'doctype.add_edit.comment' | translate }}</label>
                <div class="col-9">
                    <input class="form-control" type="text" formControlName="comment" />
                </div>
            </div>
            <div class="form-group row" *ngIf="isEdit">
                <div class="col-12">
                    <label class="col-form-label" [style]="{ color: 'black' }"
                        >{{ 'doctype.add_edit.attribute_count' | translate }}: {{ docType.attributes.length }}
                        <ng-container *ngIf="countHidden() > 0">({{ countHidden() }} {{ 'doctype.add_edit.hidden' | translate }})</ng-container></label
                    >
                </div>

                <div class="col-3">
                    <button type="button" class="btn btn-link add-attribute-btn" (click)="onAddAttribute(this.docTypeForm.get('version').value)">
                        {{ 'doctype.add_edit.add_attribute' | translate }}
                    </button>
                </div>

                <div class="col-3 offset-6 text-right">
                    <button *ngIf="showHiddenAttributes" type="button" class="btn show-hidden-attributes-button" (click)="showHiddenAttributes = false">
                        {{ 'doctype.attribute.conceal_hidden_attributes' | translate }}
                    </button>
                    <button *ngIf="!showHiddenAttributes" type="button" class="btn show-hidden-attributes-button btn-primary" (click)="showHiddenAttributes = true">
                        {{ 'doctype.attribute.display_hidden_attributes' | translate }}
                    </button>
                </div>

                <div class="col-12">
                    <table id="attributeTable" class="table-form" width="100%">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Path</th>
                                <th>Default Reference Path</th>
                                <th class="column-medium">Type</th>
                                <th class="column-small">Required</th>
                                <th class="column-small">Static</th>
                                <th class="column-small">Hidden</th>
                                <th class="column-large">Static Value</th>
                                <th class="column-large">Hidden Static Value</th>
                                <th class="column-small"></th>
                            </tr>
                        </thead>
                        <tbody *ngIf="docType.attributes.length > 0" [sortablejs]="docType.attributes">
                            <tr *ngFor="let a of docType.attributes; index as i" [ngClass]="rowClass(a)">
                                <ng-container *ngIf="editingAttribute === a" [formGroup]="attributeForm">
                                    <td class="attr-name">
                                        <input class="form-control" type="text" formControlName="name" />
                                        <div class="error" *ngIf="attributeForm.get('name').touched && attributeForm.get('name').invalid">
                                            <span *ngIf="attributeForm.get('name').errors.required">
                                                {{ 'errors.errors_require' | translate }}
                                            </span>
                                            <span *ngIf="attributeForm.get('name').errors.notUniqueName">
                                                {{ 'errors.attribute_unique_name' | translate }}
                                            </span>
                                            <span *ngIf="attributeForm.get('name').errors.illegalCharacter">
                                                {{ 'errors.attribute_wrong_name' | translate }}
                                            </span>
                                            <span *ngIf="attributeForm.get('name').errors.maxlength">
                                                {{ 'errors.attribute_name_size_error' | translate }}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <input
                                            class="form-control path"
                                            type="text"
                                            [ngbTypeahead]="pathAutocomplete.bind(this)"
                                            [inputFormatter]="pathFormatter.bind(this)"
                                            [resultFormatter]="pathFormatter.bind(this)"
                                            (selectItem)="selectedItem($event)"
                                            (focus)="pathFocus$.next($event.target.value)"
                                            (blur)="ensureEndingSlash('path')"
                                            formControlName="path"
                                        />
                                    </td>
                                    <td>
                                        <input
                                            class="form-control"
                                            type="text"
                                            [ngbTypeahead]="defaultPathAutocomplete.bind(this)"
                                            [inputFormatter]="pathFormatter.bind(this)"
                                            [resultFormatter]="pathFormatter.bind(this)"
                                            (focus)="defaultPathFocus$.next($event.target.value)"
                                            (blur)="ensureEndingSlash('defaultReference')"
                                            formControlName="defaultReference"
                                        />
                                    </td>
                                    <td>
                                        <select class="form-control" formControlName="type">
                                            <option *ngFor="let type of attributeTypes" [ngValue]="type">
                                                {{ type }}
                                            </option>
                                        </select>
                                        <div class="error" *ngIf="attributeForm.get('type').touched && attributeForm.get('type').invalid">
                                            <span *ngIf="attributeForm.get('type').errors.required">
                                                {{ 'errors.errors_require' | translate }}
                                            </span>
                                        </div>
                                    </td>
                                    <td><input type="checkbox" formControlName="required" /></td>
                                    <td><input type="checkbox" formControlName="canStatic" /></td>
                                    <td><input type="checkbox" formControlName="hidden" /></td>
                                    <td>
                                        <input class="form-control" type="text" formControlName="defaultStaticValue" />
                                        <div class="error" *ngIf="attributeForm.get('defaultStaticValue').touched && attributeForm.get('defaultStaticValue').invalid">
                                            <span *ngIf="attributeForm.get('defaultStaticValue').errors.quotesValidation">
                                                {{ 'errors.quotes_character' | translate }}
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <input class="form-control" type="text" formControlName="defaultValue" *ngIf="attributeForm.get('hidden').value" />
                                        <ng-container *ngIf="!attributeForm.get('hidden').value">&mdash;</ng-container>
                                    </td>
                                    <td class="attr-action">
                                        <span class="pointer action-link" title="{{ 'options.save' | translate }}" (click)="exitAttributeForm(true)">
                                            <i class="fa fa-check"></i>
                                        </span>
                                        <span class="pointer action-link" title="{{ 'options.cancel' | translate }}" (click)="exitAttributeForm()">
                                            <i class="fa fa-close"></i>
                                        </span>
                                    </td>
                                </ng-container>
                                <ng-container *ngIf="editingAttribute !== a && (showHiddenAttributes || (!showHiddenAttributes && !a.hidden))">
                                    <td class="attr-name pointer item-name-cell" (click)="initAttributeForm(a, i)">
                                        {{ a.name }}
                                        <ng-container *ngIf="a.description"><i class="fa fa-info-circle" placement="right" [tooltip]="a.description"></i></ng-container>
                                    </td>
                                    <td class="pointer" (click)="initAttributeForm(a, i)">{{ a.path }}</td>
                                    <td class="pointer" (click)="initAttributeForm(a, i)">
                                        {{ a.default && a.default.reference && a.default.reference.length > 0 ? a.default.reference : '&mdash;' }}
                                    </td>
                                    <td class="pointer" (click)="initAttributeForm(a, i)">{{ a.type }}</td>
                                    <td class="pointer text-center" (click)="initAttributeForm(a, i)">
                                        <i class="fa" [ngClass]="{ 'fa-check': a.required, 'fa-close': !a.required }"></i>
                                    </td>
                                    <td class="pointer text-center" (click)="initAttributeForm(a, i)">
                                        <i class="fa" [ngClass]="{ 'fa-check': a.canStatic, 'fa-close': !a.canStatic }"></i>
                                    </td>
                                    <td class="pointer text-center" (click)="initAttributeForm(a, i)">
                                        <i class="fa" [ngClass]="{ 'fa-check': a.hidden, 'fa-close': !a.hidden }"></i>
                                    </td>
                                    <td class="pointer" (click)="initAttributeForm(a, i)">
                                        {{ a.default && a.default.static && a.default.static.length > 0 ? a.default.static : '&mdash;' }}
                                    </td>
                                    <td class="pointer" (click)="initAttributeForm(a, i)">{{ a.defaultValue && a.defaultValue.length > 0 ? a.defaultValue : '&mdash;' }}</td>
                                    <td class="attr-action">
                                        <span
                                            class="pointer action-link"
                                            title="{{ 'options.edit' | translate }}"
                                            (click)="onEditAttribute(a, this.docTypeForm.get('version').value, i)"
                                        >
                                            <i class="fa fa-pencil"></i>
                                        </span>
                                        <span class="pointer action-link" title="{{ 'options.delete' | translate }}" (click)="onShowDeleteModal(a, i)">
                                            <i class="fa fa-trash"></i>
                                        </span>
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="col-12">
                    <div class="table-legend">
                        <div class="table-legend-item table-legend-for-head">Head Items</div>
                        <div class="table-legend-item table-legend-for-position">Position Items</div>
                        <div class="table-legend-item table-legend-for-special">Special Items</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" id="doctype-cancel" class="btn" (click)="modalRef.close()">{{ 'modal.cancel' | translate }}</button>
        <button type="button" id="doctype-save" [attr.disabled]="editingAttribute" class="btn btn-primary" *ngIf="!isEdit" (click)="onSave()">{{ 'modal.add' | translate }}</button>
        <button type="button" id="doctype-update" [attr.disabled]="editingAttribute" class="btn btn-primary" *ngIf="isEdit" (click)="onSave()">
            {{ 'modal.apply' | translate }}
        </button>
    </div>
</ng-template>

<app-clone-doctype-modal #cloneDoctypeModal (onSuccess)="onCloneDoctype($event)" [clientsHasDocType]="clientsHasDocType"></app-clone-doctype-modal>
<add-edit-attribute-modal #addEditAttributeModal (onSuccess)="onAttributeModified($event)"></add-edit-attribute-modal>
<ng-template #deleteModal let-deleteModalRef>
    <div class="modal-header">
        <p class="modal-title">
            <b>{{ 'clients.delete.delete_warning' | translate }}</b>
        </p>
    </div>
    <div class="modal-body">
        <p>{{ 'clients.delete.delete_attribute' | translate: { value: attributeToDelete.name } }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" ngbAutofocus id="client-file-delete-attachment-close" (click)="deleteModalRef.close()">{{ 'modal.cancel' | translate }}</button>
        <button type="button" class="btn btn-primary" id="client-file-delete-attachment" (click)="onDeleteAttribute()">{{ 'options.delete' | translate }}</button>
    </div>
</ng-template>
