<ng-template #modal let-modalRef>
    <div class="modal-header">
        <p class="modal-title" ngbAutofocus>
            <b *ngIf="!isEdit">{{ 'doctype.attribute.add_attribute' | translate }}</b>
            <b *ngIf="isEdit">{{ 'doctype.attribute.edit_attribute' | translate }}</b>
        </p>
    </div>
    <div class="modal-body" [formGroup]="attributeForm" [class.invalid]="attributeForm.invalid">
        <div class="form-group row">
            <label class="col-3 col-form-label">{{ 'doctype.attribute.name' | translate }}</label>
            <div class="col-9">
                <input class="form-control" type="text" formControlName="name" />
                <div class="error" *ngIf="attributeForm.get('name').touched && attributeForm.get('name').invalid">
                    <p *ngIf="attributeForm.get('name').errors.required">
                        {{ 'errors.errors_require' | translate }}
                    </p>
                    <p *ngIf="attributeForm.get('name').errors.notUniqueName">
                        {{ 'errors.attribute_unique_name' | translate }}
                    </p>
                    <p *ngIf="attributeForm.get('name').errors.illegalCharacter">
                        {{ 'errors.attribute_wrong_name' | translate }}
                    </p>
                    <p *ngIf="attributeForm.get('name').errors.maxlength">
                        {{ 'errors.attribute_name_size_error' | translate }}
                    </p>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-3 col-form-label">{{ 'doctype.attribute.description' | translate }}</label>
            <div class="col-9">
                <textarea class="form-control" formControlName="description"></textarea>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-3 col-form-label">{{ 'doctype.attribute.path' | translate }}</label>
            <div class="col-9">
                <div class="spinner" *ngIf="showSpinner">
                    <div></div>
                </div>
                <input
                    class="form-control path"
                    type="text"
                    [ngbTypeahead]="pathAutocomplete.bind(this)"
                    [inputFormatter]="pathFormatter.bind(this)"
                    [resultFormatter]="pathFormatter.bind(this)"
                    (selectItem)="selectedItem($event)"
                    (focus)="pathFocus$.next($event.target.value)"
                    (blur)="ensureEndingSlash('path')"
                    formControlName="path"
                />
                <div class="error" *ngIf="attributeForm.get('path').touched && attributeForm.get('path').invalid">
                    <p *ngIf="attributeForm.get('path').errors.required">
                        {{ 'errors.errors_require' | translate }}
                    </p>
                    <p *ngIf="attributeForm.get('path').errors.notUniqueName">
                        {{ 'errors.attribute_unique_path' | translate }}
                    </p>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-3 col-form-label">{{ 'doctype.attribute.default_reference_path' | translate }}</label>
            <div class="col-9">
                <div class="spinner" *ngIf="showSpinner">
                    <div></div>
                </div>
                <input
                    class="form-control"
                    type="text"
                    [ngbTypeahead]="defaultPathAutocomplete.bind(this)"
                    [inputFormatter]="pathFormatter.bind(this)"
                    [resultFormatter]="pathFormatter.bind(this)"
                    (focus)="defaultPathFocus$.next($event.target.value)"
                    (blur)="ensureEndingSlash('defaultReference')"
                    formControlName="defaultReference"
                />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-3 col-form-label">{{ 'doctype.attribute.comment_for_onboarding_team' | translate }}</label>
            <div class="col-9">
                <textarea class="form-control" formControlName="onboardingComment"></textarea>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-3 col-form-label">{{ 'doctype.attribute.type' | translate }}</label>
            <div class="col-9">
                <select class="form-control" formControlName="type">
                    <option *ngFor="let type of attributeTypes" [ngValue]="type">
                        {{ type }}
                    </option>
                </select>
                <div class="error" *ngIf="attributeForm.get('type').touched && attributeForm.get('type').invalid">
                    <p *ngIf="attributeForm.get('type').errors.required">
                        {{ 'errors.errors_require' | translate }}
                    </p>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-3 col-form-label">{{ 'doctype.attribute.default_static_value' | translate }}</label>
            <div class="col-9">
                <input class="form-control" type="text" formControlName="defaultStaticValue" />
                <div class="error" *ngIf="attributeForm.get('defaultStaticValue').touched && attributeForm.get('defaultStaticValue').invalid">
                    <p *ngIf="attributeForm.get('defaultStaticValue').errors.quotesValidation">
                        {{ 'errors.quotes_character' | translate }}
                    </p>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-3 col-form-label">{{ 'doctype.attribute.required' | translate }}</label>
            <div class="col-9">
                <input type="checkbox" formControlName="required" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-3 col-form-label">{{ 'doctype.attribute.can_be_static' | translate }}</label>
            <div class="col-9">
                <input type="checkbox" formControlName="canStatic" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-3 col-form-label">{{ 'doctype.attribute.hidden' | translate }}</label>
            <div class="col-9">
                <input type="checkbox" formControlName="hidden" />
            </div>
        </div>
        <div class="form-group row" *ngIf="attributeForm.get('hidden').value">
            <label class="col-3 col-form-label">{{ 'doctype.attribute.hidden_static_value' | translate }}</label>
            <div class="col-9">
                <input class="form-control" type="text" formControlName="defaultValue" />
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" id="attribute-cancel" class="btn btn-outline-dark" (click)="modalRef.close()">{{ 'modal.cancel' | translate }}</button>
        <button type="button" id="attribute-save" [disabled]="attributeForm.invalid" *ngIf="!isEdit" class="btn btn-primary" (click)="onSave()">
            {{ 'modal.add' | translate }}
        </button>
        <button type="button" id="attribute-update" *ngIf="isEdit" class="btn btn-primary" (click)="onSave()">{{ 'modal.apply' | translate }}</button>
    </div>
</ng-template>
