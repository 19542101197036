import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormControl, FormGroup, Validators } from '@angular/forms';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { AdminPortalService } from 'src/app/services/admin-portal.service';
import { CustomerDatabaseService } from 'src/app/services/customer-database.service';
import { ClientDatabaseService } from 'src/app/services/client-database.service';
import { CustomerModel } from '../../../models/CustomerModel';
import { MailTemplateModel } from '../../../models/MailTemplateModel';
import { EditClientFormModel } from '../../../models/EditClientFormModel';
import { ClientModel } from '../../../models/ClientModel';
import { catchError, takeUntil } from 'rxjs/operators';
import { forkJoin, of, Subject, throwError } from 'rxjs';
import { AttachmentsDatabaseService } from '../../../services/attachments.database.service';
import { NotificationService } from '../../../services/notification.service';
import { PortalService } from '../../../services/portal.service';
import { JiraCustomFieldsModel } from '../../../models/JiraCustomFieldsModel';
import { GlobalStorageService } from '../../../services/global-storage.service';

const PORTAL_LINK_DEFAULT = 'https://customer.eu01.netfira.com/';
const MAILBOX_DEFAULT = 'customer_doctype@netfiraportal.de';
const DOMAIN_DEFAULT = 'customer_domain.com';
const CONFIG_RECEIVER_LINK_DEFAULT = 'mail.netfiraportal.de';

@Component({
    selector: 'app-add-edit-modal',
    templateUrl: './add-edit-modal.component.html',
    styleUrls: ['./add-edit-modal.component.scss']
})
export class AddEditModalComponent implements OnDestroy {
    @ViewChild('addNewRef') addNewRef;
    @ViewChild('deleteModal') deleteFileModalRef;
    @ViewChild('attachmentInput') set input(input: ElementRef) {
        this.attachmentInputRef = input;
    }

    @Input() addCustomers: CustomerModel[];
    @Input() addMailTemplates: MailTemplateModel[];

    @Output() onSuccess: EventEmitter<void> = new EventEmitter();

    tabs = [
        {
            name: 'general',
            label: 'General Settings',
            fields: ['customerName', 'portal', 'portalToken', 'adminPortalSystemId', 'useAdminPortal', 'ID', 'clientName', 'adminPortalMailboxId'],
            invalid: false
        },
        { name: 'email', label: 'Mailbox Settings', fields: ['mail', 'emailPass', 'mailTemplates', 'adminPortalMailboxId', 'domain', 'configReceiver'], invalid: false },
        { name: 'jira', label: 'Jira Settings', fields: ['jiraProjectKey'], invalid: false },
        { name: 'notification', label: 'Notification Settings', fields: ['language', 'contactSalutation', 'contactName', 'primaryContactEmail'], invalid: false },
        { name: 'onboarding', label: 'Onboarding Settings', fields: ['uploadDocumentsCount', 'enableBasicConnections'], invalid: false }
    ];
    tabOrder = ['general', 'email', 'jira', 'notification', 'onboarding'];
    currentTab: 'general' | 'email' | 'jira' | 'notification' | 'onboarding' = 'general';

    type: number;
    submit: boolean;
    isEdit: boolean;
    clientForm: FormGroup;
    updatedItem: EditClientFormModel;

    deletedFileName: string;
    attachmentInputRef: ElementRef;
    isCheckPortalClicked: boolean = false;
    portalErrorMessage = '';
    jiraCustomFields: JiraCustomFieldsModel;
    showPassword: boolean;
    enableBasicConnections: Boolean;

    isDefaultClient: boolean = false;

    typesForm = new FormGroup({
        type: new FormControl('0'),
        forBuyer: new FormControl('')
    });

    triedSubmitting = false;

    private modal: NgbModalRef;
    private deleteModal: NgbModalRef;
    private unsubscribe: Subject<void> = new Subject();
    private resetPassword = false;

    constructor(
        protected modalService: NgbModal,
        protected addBuyerService: CustomerDatabaseService,
        protected clientRepository: ClientDatabaseService,
        protected attachmentRepository: AttachmentsDatabaseService,
        protected notificationService: NotificationService,
        protected portalService: PortalService,
        protected adminPortalService: AdminPortalService,
        protected globalStorage: GlobalStorageService
    ) {
        this.type = 0;
        this.submit = false;
    }

    public setFormData(updatedItem?: EditClientFormModel) {
        this.typesForm.get('forBuyer').valueChanges.subscribe((val) => {
            const customer = this.addCustomers.filter((c) => c._id == val)[0];
            this.isDefaultClient = false;

            if (!customer) {
                return;
            }

            const { name, portal, portalToken, adminPortalSystemId } = customer;

            this.clientForm.get('portal').setValue(portal);
            this.clientForm.get('portalToken').setValue(portalToken);
            this.clientForm.get('customerName').setValue(name);
            this.clientForm.get('adminPortalSystemId').setValue(adminPortalSystemId);
        });

        this.isDefaultClient = !updatedItem || (updatedItem && updatedItem.ID === '00000');

        this.clientForm = new FormGroup({
            customerName: new FormControl(updatedItem ? updatedItem.customerName : '', this.isDefaultClient ? Validators.required : null),
            portal: new FormControl(
                updatedItem ? updatedItem.portal : PORTAL_LINK_DEFAULT,
                this.isDefaultClient ? [Validators.required, potralLinkValidation, potralUrlPortValidation] : null
            ),
            portalToken: new FormControl(updatedItem ? updatedItem.portalToken : ''),
            adminPortalSystemId: new FormControl({ value: updatedItem ? updatedItem.adminPortalSystemId : '', disabled: true }),
            hasPassword: new FormControl(updatedItem && updatedItem.hasPassword ? updatedItem.hasPassword : false),
            password: new FormControl({
                value: '',
                disabled: updatedItem && updatedItem.canLoginViaPortal && updatedItem.portalAuthorization ? true : false
            }),
            portalAuthorization: new FormControl(updatedItem && updatedItem.canLoginViaPortal ? updatedItem.portalAuthorization : false),
            ID: new FormControl(updatedItem ? updatedItem.ID : '00000', Validators.required),
            clientName: new FormControl(updatedItem ? updatedItem.clientName : '', Validators.required),
            adminPortalClientId: new FormControl(updatedItem ? updatedItem.adminPortalClientId : ''),

            mail: new FormControl(updatedItem ? updatedItem.mail : MAILBOX_DEFAULT, Validators.required),
            mailPassword: new FormControl(updatedItem ? updatedItem.mailPassword : '', Validators.required),
            adminPortalMailboxId: new FormControl(updatedItem ? updatedItem.adminPortalMailboxId : ''),
            mailTemplates: new FormControl(updatedItem ? updatedItem.mailTemplates : []),
            domain: new FormArray(updatedItem ? this.getContactDomains(updatedItem.domain) : this.getContactDomains()),
            configReceiver: new FormControl(updatedItem ? updatedItem.configReceiver : CONFIG_RECEIVER_LINK_DEFAULT),
            useAdminPortal: new FormControl(updatedItem ? updatedItem.useAdminPortal : true),

            jiraProjectKey: new FormControl(updatedItem ? updatedItem.jiraProjectKey : 'ON', Validators.required),

            language: new FormControl(updatedItem ? updatedItem.language : 'de', Validators.required),
            contactSalutation: new FormControl(updatedItem ? updatedItem.contactSalutation : '', Validators.required),
            contactName: new FormControl(updatedItem ? updatedItem.contactName : '', Validators.required),
            contactEmail: new FormArray(updatedItem ? this.getContactEmail(updatedItem.contactEmail) : this.getContactEmail()),
            primaryContactEmail: new FormControl(updatedItem ? this.setPrimaryEmail(updatedItem.contactEmail) : '0', [Validators.required]),

            uploadDocumentsCount: new FormControl(updatedItem && updatedItem.uploadDocumentsCount ? updatedItem.uploadDocumentsCount : 3, [Validators.required, Validators.min(1)]),
            autoOnboardingTarget: new FormControl(updatedItem && updatedItem.autoOnboardingTarget ? updatedItem.autoOnboardingTarget : false),
            predictionAccepted: new FormControl(updatedItem && updatedItem.hasOwnProperty('predictionAccepted') ? updatedItem.predictionAccepted : false),
            enableBasicConnections: new FormControl(updatedItem && updatedItem.enableBasicConnections ? updatedItem.enableBasicConnections : false)
        });

        this.clientForm.get('portalAuthorization').valueChanges.subscribe((val) => {
            if (val) {
                this.clientForm.get('password').setValue('');
                this.clientForm.get('password').disable();
            } else {
                this.clientForm.get('password').enable();
            }
        });

        this.clientForm.statusChanges.subscribe(() => {
            if (this.triedSubmitting) {
                this.highlightInvalidTabs();
            }
        });
    }

    private setPrimaryEmail(
        emailList: {
            address: string;
            active: boolean;
            primary: boolean;
        }[]
    ): number {
        let primaryIndexEmail = 0;
        emailList.forEach((email, index) => {
            if (email.primary) {
                primaryIndexEmail = index;
            }
        });
        return primaryIndexEmail;
    }

    private getContactEmail(
        emailList?: {
            address: string;
            active: boolean;
            primary: boolean;
        }[]
    ): any {
        if (!emailList || emailList.length == 0) {
            return [
                new FormGroup({
                    address: new FormControl('', [Validators.required, Validators.email]),
                    isActive: new FormControl({ value: true, disabled: true })
                })
            ];
        }
        const formControlList = [];
        emailList.forEach((email) => {
            const isPrimary = email.primary;
            formControlList.push(
                new FormGroup({
                    address: new FormControl(email.address, [Validators.required, Validators.email]),
                    isActive: new FormControl({ value: isPrimary ? true : email.active, disabled: isPrimary })
                })
            );
        });
        return formControlList;
    }

    private getContactDomains(domainsList?: string[]): any {
        if (!domainsList || domainsList.length == 0) {
            return [new FormControl(DOMAIN_DEFAULT)];
        }
        const formControlList = [];
        domainsList.forEach((domain) => {
            formControlList.push(new FormControl(domain));
        });
        return formControlList;
    }

    continue() {
        const index = this.tabOrder.indexOf(this.currentTab);
        this.currentTab = <any>this.tabOrder[index + 1];
    }

    onSetPrimaryEmail(formArray: FormGroup[], index) {
        formArray[index].controls.isActive.setValue(true);
        formArray.forEach((email, i) => {
            const isActive: AbstractControl = email.controls.isActive;
            if (index == i) {
                isActive.disable();
            } else {
                isActive.enable();
            }
        });
    }

    addFormDomain(form: FormGroup, field: string) {
        const control = <FormArray>form.controls[field];
        control.push(new FormControl(''));
    }

    addFormEmailItem(form: FormGroup, field: string) {
        const control = <FormArray>form.controls[field];
        control.controls.push(
            new FormGroup({
                address: new FormControl('', [Validators.required, Validators.email]),
                isActive: new FormControl(false)
            })
        );
    }

    changeTab(tab: 'general' | 'email' | 'jira' | 'notification' | 'onboarding') {
        this.currentTab = tab;
    }

    removeFormArrayItem(form: FormGroup, field: string, i: number) {
        const fc = form.controls[field] as FormArray;
        let primaryContactEmail = parseInt(form.get('primaryContactEmail').value);
        const isPrimary = primaryContactEmail == i;
        if (fc.controls.length == 1 || isPrimary) {
            return;
        }
        const control = <FormArray>form.controls[field];
        control.removeAt(i);
        if (primaryContactEmail > i) {
            primaryContactEmail--;
            setTimeout(() => {
                form.get('primaryContactEmail').setValue(primaryContactEmail);
            }, 0);
        }
    }

    testPortalConnection() {
        let url = this.clientForm.get('portal').value;
        let token = this.clientForm.get('portalToken').value;
        this.isCheckPortalClicked = true;
        this.portalService
            .testPortalUrl(url, token)
            .pipe(
                catchError((err) => {
                    if (err.status == 400) {
                        this.portalErrorMessage = err;
                        this.notificationService.notify(err.error.dictionaryKey);
                        return of(undefined);
                    }
                    return throwError(err);
                })
            )
            .subscribe((resp) => {
                if (resp === undefined) {
                    return;
                }
                this.portalErrorMessage = '';
                this.notificationService.notify('errors.test_portal_connection_successful', 'success');
            });
    }

    onShowModal(updatedItem?: EditClientFormModel) {
        this.portalErrorMessage = '';
        this.isCheckPortalClicked = false;
        this.resetPassword = false;
        this.showPassword = false;
        this.type = 0;
        this.typesForm.reset();

        if (updatedItem) {
            this.isEdit = true;
            this.updatedItem = updatedItem;
        } else {
            this.isEdit = false;
            this.typesForm.get('type').setValue(0);
        }

        this.globalStorage.getJiraCustomFields().subscribe((data) => {
            this.jiraCustomFields = data;
            this.setFormData(updatedItem);
            this.modal = this.modalService.open(this.addNewRef, { backdrop: 'static', keyboard: false, ariaLabelledBy: 'modal-basic-title', size: <any>'xl' });
        });
    }

    hasFieldGotErrors(fieldName): boolean {
        return this.clientForm.get(fieldName).touched && this.clientForm.get(fieldName).invalid;
    }

    onShowDeleteFileModal(fileName: string) {
        this.deletedFileName = fileName;
        this.deleteModal = this.modalService.open(this.deleteFileModalRef);
    }

    onDeleteFile() {
        this.attachmentRepository
            .deleteFile(this.updatedItem.getClient()._id, this.deletedFileName)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (data) => {
                    this.getClientAttachments(this.updatedItem.getClient()._id);
                    this.deleteModal.close();
                },
                (error) => this.deleteModal.close()
            );
    }

    getClientAttachments(clientId: string) {
        this.attachmentRepository
            .getClientFiles(clientId)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data: any) => {
                this.updatedItem.getClient().files = data.files;
            });
    }

    onResetPassword() {
        this.resetPassword = true;
        this.clientForm.get('password').setValue('');
        this.clientForm.get('hasPassword').setValue(false);
        this.submitForm(this.clientForm);
    }

    resetTabs() {
        this.tabs.forEach((tab) => {
            tab.invalid = false;
        });
    }

    highlightInvalidTabs(autoSwitch: boolean = false) {
        this.resetTabs();

        for (let key in this.clientForm.controls) {
            if (this.clientForm.controls[key].invalid) {
                const tab = this.tabs.find((t) => t.fields.includes(key));

                if (tab) {
                    tab.invalid = true;
                }
            }
        }

        if (autoSwitch) {
            const firstInvalidTab = this.tabs.find((tab) => tab.invalid);

            if (firstInvalidTab) {
                this.currentTab = <any>firstInvalidTab.name;
            }
        }
    }

    submitForm(form: FormGroup) {
        this.triedSubmitting = true;

        for (let key in form.controls) {
            form.controls[key].markAllAsTouched();
            form.controls[key].updateValueAndValidity();
        }

        if (form.invalid) {
            this.highlightInvalidTabs(true);
            this.submit = true;
            return;
        }

        // fetch and verify admin portal system, client, and mailbox records if useAdminPortal is set to true
        console.log('Checking admin portal data');
        this.adminPortalService
            .postAdminPortalGraphQl(
                `query{
                systems{
                    edges{
                        node{
                            id
                            namespace
                            domain
                            clients{
                                id
                                reference
                                name
                                mailboxes{
                                    id
                                    mail_address
                                }
                            }
                        } 
                    }
                }
            }`
            )
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            )
            .subscribe((data) => {
                // run checks on admin portal data
                let apSystem, apClient, apMailbox;
                let apSystemId, apClientId, apMailboxId;
                let errorMsg = null;

                //First, check system exists
                apSystem = data.data.data.systems.edges.filter(
                    (systems) => 'https://' + systems.node.namespace + '.' + systems.node.domain + '.netfira.com' === form.get('portal').value.slice(0, -1)
                );
                if (apSystem.length > 0) {
                    apSystemId = apSystem[0].node.id;

                    //Second, check a client exists within this system
                    if (apSystem[0].node.clients.length == 0) {
                        errorMsg = `No clients exist in admin portal for system '${form.get('portal').value}'`;
                    } else {
                        apClient = apSystem[0].node.clients.filter((client) => client.reference === form.get('ID').value);

                        if (apClient.length > 0) {
                            apClientId = apClient[0].id;

                            //Finally, check a mailbox exists for this client
                            if (apClient[0].mailboxes.length == 0) {
                                errorMsg = `No mailboxes exist in admin portal for client ${form.get('ID').value}`;
                            } else {
                                apMailbox = apClient[0].mailboxes.filter((mailbox) => mailbox.mail_address === form.get('mail').value);
                                if (apMailbox.length > 0) {
                                    apMailboxId = apMailbox[0].id;
                                    errorMsg = null;
                                } else {
                                    errorMsg = `No mailbox exists in admin portal with address '${form.get('mail').value}' for client '${form.get('ID').value}'`;
                                }
                            }
                        } else {
                            errorMsg = `No client exists in admin portal with Id '${form.get('ID').value}' for system '${form.get('portal').value}'`;
                        }
                    }
                } else {
                    errorMsg = `No system exists in admin portal with url '${form.get('portal').value}'`;
                }

                // return error or update values if admin portal is being used
                if (form.get('useAdminPortal').value == true) {
                    if (errorMsg) {
                        this.notificationService.notify(errorMsg, 'error');
                        return;
                    } else {
                        console.log('Patching AP values');
                        form.patchValue({
                            adminPortalSystemId: apSystemId,
                            adminPortalClientId: apClientId,
                            adminPortalMailboxId: apMailboxId
                        });
                    }
                } else {
                    //if not using admin portal, ensure that basic connections are not enabled
                    form.patchValue({
                        enableBasicConnections: false
                    });
                }

                // if we are updating an existing client, revert to onUpdateClient method
                if (this.isEdit) {
                    console.log('Updating client');
                    this.onUpdateClient(form);
                    return;
                }

                // else, proceed with create client method
                const clientModel = this.getClientRequestData(form);

                // check if this request is for a completely new system
                if (this.type === 0) {
                    let customerModel = this.getCustomerRequestData(form);
                    customerModel.defaultClient = clientModel;
                    this.addBuyerService
                        .add(customerModel)
                        .pipe(
                            takeUntil(this.unsubscribe),
                            catchError((err) => {
                                return throwError(err);
                            })
                        )
                        .subscribe((data) => {
                            this.resetForm();
                        });
                    // otherwise it is for an existing system
                } else if (this.type === 1) {
                    clientModel['customer'] = this.typesForm.get('forBuyer').value;
                    this.clientRepository
                        .add(clientModel)
                        .pipe(
                            takeUntil(this.unsubscribe),
                            catchError((err) => {
                                return throwError(err);
                            })
                        )
                        .subscribe((data) => {
                            this.resetForm();
                        });
                }
            });
    }

    private resetForm() {
        this.modal.close();
        this.toggleForBuyerSelect(0);
        this.typesForm.get('type').setValue(0);
        this.onSuccess.emit();
        this.triedSubmitting = false;
        this.resetTabs();
        this.currentTab = 'general';
    }

    private onUpdateClient(form: FormGroup) {
        const clientId = this.updatedItem.getClient()._id;
        const customerId = this.updatedItem.getCustomer()._id;
        if (form.get('portalAuthorization').value && form.get('hasPassword').value) {
            this.onResetPassword();
            this.resetPassword = false;
        }
        const clientUpdate = this.updateClient(clientId, this.getClientRequestData(form)).pipe(
            catchError((err) => {
                this.setFormData(this.updatedItem);
                this.updateCustomer(customerId, this.getCustomerRequestData(this.clientForm)).pipe(takeUntil(this.unsubscribe)).subscribe();
                return throwError(err);
            })
        );
        const customerUpdate = this.updateCustomer(customerId, this.getCustomerRequestData(form)).pipe(
            catchError((err) => {
                this.setFormData(this.updatedItem);
                this.updateClient(clientId, this.getClientRequestData(this.clientForm)).pipe(takeUntil(this.unsubscribe)).subscribe();
                return throwError(err);
            })
        );
        forkJoin(clientUpdate, customerUpdate)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.onSuccess.emit();
                if (this.resetPassword) {
                    this.resetPassword = false;
                    return;
                }
                this.modal.close();
            });
    }

    private updateClient(clientId: string, clientModel: ClientModel) {
        return this.clientRepository.update(clientId, clientModel);
    }

    private updateCustomer(customerId: string, customerModel: CustomerModel) {
        return this.addBuyerService.update(customerId, customerModel);
    }

    getClientRequestData(form?: FormGroup): ClientModel {
        const formData = form ? form.getRawValue() : null;
        if (this.type == 0) {
            // Configured request model for adding new customer or editing existing customer with default client.
            const client = this.updatedItem ? this.updatedItem.getClient() : null;

            let requestData = {
                contact: {
                    name: formData.contactName,
                    formOfAddress: formData.contactSalutation,
                    emails: []
                },
                id: formData.ID,
                mail: formData.mail,
                mailPassword: formData.mailPassword,
                configReceiver: formData.configReceiver,
                mailTemplates: formData.mailTemplates,
                name: formData.clientName,
                language: formData.language,
                jiraProjectKey: formData.jiraProjectKey,
                aiPrediction: true,
                domains: formData.domain,
                portalAuthorization: formData.portalAuthorization,
                uploadDocumentsCount: parseInt(formData.uploadDocumentsCount, 10),
                autoOnboardingTarget: formData.autoOnboardingTarget,
                predictionAccepted: formData.predictionAccepted,
                adminPortalMailboxId: formData.adminPortalMailboxId,
                adminPortalClientId: formData.adminPortalClientId,
                useAdminPortal: formData.useAdminPortal,
                enableBasicConnections: formData.enableBasicConnections
            };
            if (client) {
                requestData = Object.assign({}, client, requestData);
            }

            let contactEmails = formData.contactEmail;
            let emails = [];
            if (contactEmails.length == 1) {
                const contactEmailModel = contactEmails[0];
                emails.push({
                    address: contactEmailModel.address,
                    active: contactEmailModel.isActive,
                    primary: true
                });
            } else {
                contactEmails.forEach((email: any, index: number) => {
                    emails.push({
                        address: email.address,
                        active: email.isActive,
                        primary: index == formData.primaryContactEmail
                    });
                });
            }
            requestData.contact['emails'] = emails;
            // Sending password if it was set
            if (form.get('password').value != '' || this.resetPassword) {
                requestData['password'] = form.get('password').value;
            }

            return requestData;
        }
        // Configured request model for adding client.
        let requestData = {
            contact: {
                name: formData.contactName,
                formOfAddress: formData.contactSalutation,
                emails: []
            },
            id: formData.ID,
            mail: formData.mail,
            configReceiver: formData.configReceiver,
            mailPassword: formData.mailPassword,
            mailTemplates: formData.mailTemplates ? formData.mailTemplates : [],
            name: formData.clientName,
            language: formData.language,
            jiraProjectKey: formData.jiraProjectKey,
            aiPrediction: true,
            domains: form ? formData.domain : [''],
            portalAuthorization: form ? formData.portalAuthorization : false,
            uploadDocumentsCount: form ? parseInt(formData.uploadDocumentsCount, 10) : 3,
            autoOnboardingTarget: formData.autoOnboardingTarget,
            predictionAccepted: formData.predictionAccepted,
            adminPortalMailboxId: formData.adminPortalMailboxId,
            adminPortalClientId: formData.adminPortalClientId,
            useAdminPortal: formData.useAdminPortal,
            enableBasicConnections: formData.enableBasicConnections
        };
        console.log('requestData v2', requestData);

        // Sending password if it was set
        if (formData.password != '') {
            requestData['password'] = formData.password;
        }
        let contactEmails = formData.contactEmail;
        let emails = [];
        if (contactEmails.length == 1) {
            const contactEmailModel = contactEmails[0];
            emails.push({
                address: contactEmailModel.address,
                active: contactEmailModel.isActive,
                primary: true
            });
        } else {
            contactEmails.forEach((email: any, index: number) => {
                emails.push({
                    address: email.address,
                    active: email.isActive,
                    primary: index == formData.primaryContactEmail
                });
            });
        }
        requestData.contact['emails'] = emails;
        return requestData;
    }

    getCustomerRequestData(form?: FormGroup): CustomerModel {
        const customer = this.updatedItem ? this.updatedItem.getCustomer() : null;
        return {
            name: form ? form.get('customerName').value : customer.name,
            portal: form ? form.get('portal').value : customer.portal,
            portalToken: form ? form.get('portalToken').value : customer.portalToken,
            adminPortalSystemId: form ? form.get('adminPortalSystemId').value : customer.adminPortalSystemId,
            defaultClient: customer ? customer.defaultClient : null
        };
    }

    toggleForBuyerSelect(typeValue) {
        this.submit = false;
        this.type = +typeValue;

        if (this.type === 0) {
            this.isDefaultClient = true;
            this.clientForm.reset();
            this.clientForm.get('customerName').setValidators(Validators.required);
            this.clientForm.get('portal').setValidators([Validators.required, potralLinkValidation, potralUrlPortValidation]);
        } else if (this.type === 1) {
            this.isDefaultClient = false;
            this.clientForm.reset();
            this.typesForm.get('forBuyer').setValue(this.addCustomers[0]._id);
            this.clientForm.get('customerName').clearValidators();
            this.clientForm.get('portal').clearValidators();
        }

        this.resetTabs();
    }

    cancel() {
        this.resetForm();
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
        this.showPassword = false;
    }
}

function potralLinkValidation(control: FormControl) {
    if (control.value && (!/^(http|https):/.test(control.value) || !control.value.endsWith('/'))) {
        return { portalLinkEndCharacter: true };
    }
    return null;
}

function potralUrlPortValidation(control: FormControl) {
    if (control.value && /^(http):/.test(control.value) && !/:\d{1,}\//.test(control.value)) {
        return { portalPortValidation: true };
    }
    return null;
}
