<ng-template #addNewRef let-modal class="add-new-client-customer-modal" xmlns="http://www.w3.org/1999/html">
    <div class="modal-header">
        <div ngbAutofocus>
            <span *ngIf="!isEdit; else editModalTitle">{{ 'clients.add_new' | translate }}</span>
            <ng-template #editModalTitle>
                <span>{{ 'clients.edit_entry' | translate }}</span>
            </ng-template>
        </div>
    </div>

    <div class="modal-body" id="add-modal-body">
        <div class="row">
            <div class="col-sm-4">
                <form [formGroup]="typesForm" autocomplete="new-password">
                    <div class="form-inline col-12" *ngIf="!isEdit && addCustomers.length > 0; else editBuyerTitle">
                        <div class="mr-sm-3">
                            <select class="custom-select mb-2 mr-sm-2 mb-sm-0" formControlName="type" (change)="toggleForBuyerSelect($event.target.value)">
                                <option value="0">{{ 'clients.new_system' | translate }}</option>
                                <option value="1">{{ 'clients.new_client_for_system' | translate }}</option>
                            </select>
                        </div>

                        <div class="mr-sm-2">
                            <select [attr.disabled]="type != 1 ? '' : null" class="custom-select mb-2 mr-sm-2 mb-sm-0" formControlName="forBuyer">
                                <option *ngFor="let customer of addCustomers" [ngValue]="customer._id">
                                    {{ customer.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                    <ng-template #editBuyerTitle>
                        <h4 class="label">{{ 'clients.add_new_component.buyer_settings' | translate }}</h4>
                    </ng-template>
                </form>
            </div>
        </div>

        <div class="row tab-navigation">
            <div *ngFor="let tab of tabs" class="col-sm-2 tab" [ngClass]="{ active: tab.name === currentTab, invalid: tab.invalid }" (click)="changeTab(tab.name)">
                {{ tab.label }}
            </div>
        </div>

        <div [formGroup]="clientForm" [className]="submit ? 'invalid' : ''" autocomplete="new-password">
            <ng-container *ngIf="currentTab === 'general'">
                <div class="form-group row">
                    <label class="col-2 col-form-label"
                        >{{ 'clients.add_new_component.customer_name' | translate }}
                        <i class="fa fa-info-circle" placement="right" [tooltip]="'clients.add_new_component.customer_name_tooltip' | translate"></i>
                    </label>
                    <div class="col-6">
                        <input class="form-control" [attr.disabled]="isEdit ? 'disabled' : null" type="text" formControlName="customerName" />
                        <div class="error" *ngIf="clientForm.get('customerName').touched && clientForm.get('customerName').invalid">
                            <p *ngIf="clientForm.get('customerName').errors.required">
                                {{ 'errors.errors_require' | translate }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-2 col-form-label"
                        >{{ 'clients.portal_link' | translate }}
                        <i class="fa fa-info-circle" placement="right" [tooltip]="'clients.add_new_component.portal_link_tooltip' | translate"></i>
                    </label>
                    <div class="col-6">
                        <input class="form-control" [attr.disabled]="isEdit ? 'disabled' : null" type="text" formControlName="portal" />
                        <div class="error" *ngIf="hasFieldGotErrors('portal')">
                            <p *ngIf="clientForm.get('portal').errors.required">
                                {{ 'errors.errors_require' | translate }}
                            </p>
                            <p *ngIf="clientForm.get('portal').errors.portalLinkEndCharacter">
                                {{ 'errors.portal_url_end_character' | translate }}
                            </p>
                            <p *ngIf="clientForm.get('portal').errors.portalPortValidation">
                                {{ 'errors.portal_url_port' | translate }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-2 col-form-label"
                        >{{ 'clients.add_new_component.portal_token' | translate }}
                        <i class="fa fa-info-circle" placement="right" [tooltip]="'clients.add_new_component.portal_token_tooltip' | translate"></i>
                    </label>
                    <div class="col-6">
                        <input class="form-control" type="text" formControlName="portalToken" />
                    </div>
                    <div class="col-4">
                        <button
                            class="btn btn-sm test-connection-btn"
                            [ngClass]="{
                                'btn-primary': !isCheckPortalClicked,
                                'btn-danger': isCheckPortalClicked && portalErrorMessage != '',
                                'btn-success': isCheckPortalClicked && portalErrorMessage == ''
                            }"
                            (click)="testPortalConnection()"
                            title="Test Connection"
                        >
                            <i
                                class="fa"
                                [ngClass]="{
                                    'fa-refresh': !isCheckPortalClicked,
                                    'fa-ban': isCheckPortalClicked && portalErrorMessage != '',
                                    'fa-check': isCheckPortalClicked && portalErrorMessage == ''
                                }"
                            ></i>
                        </button>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="admin-portal-system-id" class="col-2 col-form-label">
                        <span>{{ 'clients.add_new_component.admin_portal_system_id' | translate }}</span>
                        <i class="fa fa-info-circle" placement="right" [tooltip]="'clients.add_new_component.admin_portal_system_id_tooltip' | translate"></i>
                    </label>
                    <div class="col-6">
                        <input class="form-control" type="text" formControlName="adminPortalSystemId" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-2 col-form-label"
                        >{{ 'clients.add_new_component.use_admin_portal' | translate }}
                        <i class="fa fa-info-circle" placement="right" [tooltip]="'clients.add_new_component.use_admin_portal_tooltip' | translate"></i>
                    </label>
                    <div class="col-6">
                        <input type="checkbox" style="margin: 11px 0 20px" formControlName="useAdminPortal" />
                    </div>
                </div>

                <div class="form-group row">
                    <label for="portal-password" class="col-2 col-form-label">
                        <span>{{ 'clients.add_new_component.use_portal_password' | translate }}</span>
                        <i class="fa fa-info-circle" placement="right" [tooltip]="'clients.add_new_component.password_tooltip' | translate"></i>
                    </label>
                    <div class="col-6">
                        <input type="checkbox" style="margin: 11px 0 20px 0" id="portal-password" formControlName="portalAuthorization" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-2 col-form-label"
                        >{{ 'clients.add_new_component.password' | translate }}
                        <i class="fa fa-info-circle" placement="right" [tooltip]="'clients.add_new_component.use_portal_logins_tooltip' | translate"></i>
                    </label>
                    <div class="col-6">
                        <input
                            type="password"
                            [placeholder]="clientForm.get('hasPassword').value ? '*********' : ''"
                            autocomplete="new-password"
                            id="client-password"
                            class="form-control"
                            formControlName="password"
                        />
                        <div class="row">
                            <div class="col-12 col-sm-6 col-md-8">
                                <div *ngIf="showPassword" class="password">{{ clientForm.get('password').value }}</div>
                            </div>
                            <div class="text-right col-12 col-sm-6 col-md-4">
                                <span class="pointer show-password-btn" id="on-show-passowrd" *ngIf="clientForm.get('password').value != ''" (click)="showPassword = !showPassword"
                                    >Show password</span
                                >
                            </div>
                            <div *ngIf="isEdit && clientForm.get('hasPassword').value" class="text-right">
                                <a data-href="#" class="pointer" (click)="onResetPassword()">Reset Password</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-2 col-form-label"
                        >{{ 'clients.add_new_component.client_id' | translate }}
                        <i class="fa fa-info-circle" placement="right" [tooltip]="'clients.add_new_component.client_id_tooltip' | translate"></i>
                    </label>
                    <div class="col-6">
                        <input class="form-control" [attr.disabled]="isEdit ? 'disabled' : null" type="text" formControlName="ID" />
                        <div class="error" *ngIf="hasFieldGotErrors('ID')">
                            <p *ngIf="clientForm.get('ID').errors.required">
                                {{ 'errors.errors_require' | translate }}
                            </p>
                            <p *ngIf="clientForm.get('ID').errors.maxlength">
                                {{ 'errors.id_length' | translate }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-2 col-form-label"
                        >{{ 'clients.add_new_component.client_name' | translate }}
                        <i class="fa fa-info-circle" placement="right" [tooltip]="'clients.add_new_component.client_name_tooltip' | translate"></i>
                    </label>
                    <div class="col-6">
                        <input class="form-control" [attr.disabled]="isEdit ? 'disabled' : null" type="text" formControlName="clientName" />
                        <div class="error" *ngIf="hasFieldGotErrors('clientName')">
                            <p *ngIf="clientForm.get('clientName').errors.required">
                                {{ 'errors.errors_require' | translate }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="admin-portal-client-id" class="col-2 col-form-label">
                        <span>{{ 'clients.add_new_component.admin_portal_client_id' | translate }}</span>
                        <i class="fa fa-info-circle" placement="right" [tooltip]="'clients.add_new_component.admin_portal_client_id_tooltip' | translate"></i>
                    </label>
                    <div class="col-6">
                        <input class="form-control" type="text" formControlName="adminPortalClientId" [attr.disabled]="true" />
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="currentTab === 'email'">
                <div class="form-group row">
                    <label class="col-2 col-form-label"
                        >{{ 'clients.add_new_component.email' | translate }}&nbsp;<i
                            class="fa fa-info-circle"
                            placement="right"
                            [tooltip]="'clients.add_new_component.email_tooltip' | translate"
                        ></i
                    ></label>
                    <div class="col-6">
                        <div class="input-group w-100">
                            <input class="form-control" type="text" formControlName="mail" />
                        </div>

                        <div class="error" *ngIf="hasFieldGotErrors('mail')">
                            <p *ngIf="clientForm.get('mail').errors.required">
                                {{ 'errors.errors_require' | translate }}
                            </p>
                            <p *ngIf="clientForm.get('mail').errors.checkForEmail">
                                {{ 'errors.invalid_email' | translate }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-2 col-form-label"
                        >{{ 'clients.add_new_component.e-mail_password' | translate }}&nbsp;<i
                            class="fa fa-info-circle"
                            placement="right"
                            [tooltip]="'clients.add_new_component.email_password_tooltip' | translate"
                        ></i
                    ></label>
                    <div class="col-6">
                        <input class="form-control" type="text" formControlName="mailPassword" />
                        <div class="error" *ngIf="hasFieldGotErrors('mailPassword')">
                            <p *ngIf="clientForm.get('mailPassword').errors.required">
                                {{ 'errors.errors_require' | translate }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label for="admin-portal-mailbox-id" class="col-2 col-form-label">
                        <span>{{ 'clients.add_new_component.admin_portal_mailbox_id' | translate }}</span>
                        <i class="fa fa-info-circle" placement="right" [tooltip]="'clients.add_new_component.admin_portal_mailbox_id_tooltip' | translate"></i>
                    </label>
                    <div class="col-6">
                        <input class="form-control" type="text" formControlName="adminPortalMailboxId" [attr.disabled]="true" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-2 col-form-label"
                        >{{ 'clients.mail_templates' | translate }}
                        <i class="fa fa-info-circle" placement="right" [tooltip]="'clients.add_new_component.email_templates_tooltip' | translate"></i>
                    </label>

                    <div *ngIf="!clientForm.get('useAdminPortal').value" class="col-6">
                        <select multiple class="form-control" formControlName="mailTemplates">
                            <option *ngFor="let mailTemplates of addMailTemplates; trackBy: objectId; index as i" [ngValue]="mailTemplates._id">
                                {{ mailTemplates.displayName }}
                            </option>
                        </select>
                        <div class="error" *ngIf="hasFieldGotErrors('mailTemplates')">
                            <p *ngIf="clientForm.get('mailTemplates').errors.required">
                                {{ 'errors.errors_require' | translate }}
                            </p>
                        </div>
                    </div>
                    <label *ngIf="clientForm.get('useAdminPortal').value" class="col-6 col-form-label">{{ 'errors.mail_template_warning' | translate }} </label>
                </div>

                <div class="form-group row domain-control">
                    <label class="col-2 col-form-label"
                        >{{ 'global.domain' | translate }}&nbsp;<i
                            class="fa fa-info-circle"
                            placement="right"
                            [tooltip]="'clients.add_new_component.domain_tooltip' | translate"
                        ></i
                    ></label>
                    <div *ngIf="!clientForm.get('useAdminPortal').value" class="col-6" formArrayName="domain">
                        <div *ngFor="let domain of clientForm.get('domain').controls; let i = index">
                            <input class="form-control" type="text" [formControl]="domain" />
                            <button class="btn btn-danger" (click)="removeFormArrayItem(clientForm, 'domain', i)">-</button>
                            <button class="btn btn-success" *ngIf="i == 0" (click)="addFormDomain(clientForm, 'domain')">+</button>
                        </div>
                        <div class="error" *ngIf="clientForm.get('domain').touched && clientForm.get('domain').invalid">
                            <p>
                                {{ 'errors.errors_require' | translate }}
                            </p>
                        </div>
                    </div>
                    <label *ngIf="clientForm.get('useAdminPortal').value" class="col-6 col-form-label">{{ 'errors.mail_domains_warning' | translate }} </label>
                </div>

                <div class="form-group row">
                    <label class="col-2 col-form-label"
                        >{{ 'clients.add_new_component.config_reciever' | translate }}
                        <i class="fa fa-info-circle" placement="right" [tooltip]="'clients.add_new_component.config_receiver_tooltip' | translate"></i>
                    </label>
                    <label *ngIf="clientForm.get('useAdminPortal').value" class="col-6 col-form-label">{{ 'errors.mail_server_warning' | translate }} </label>
                    <div *ngIf="!clientForm.get('useAdminPortal').value" class="col-6">
                        <input class="form-control" type="text" formControlName="configReceiver" />
                        <div class="error" *ngIf="clientForm.get('configReceiver').touched && clientForm.get('configReceiver').invalid">
                            <p *ngIf="clientForm.get('configReceiver').errors.required">
                                {{ 'errors.errors_require' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="currentTab === 'jira'">
                <div class="form-group row">
                    <label class="col-2 col-form-label"
                        >{{ 'clients.add_new_component.jiraProjectKey' | translate }}
                        <i class="fa fa-info-circle" placement="right" [tooltip]="'clients.add_new_component.project_key_tooltip' | translate"></i>
                    </label>
                    <div class="col-6">
                        <select class="form-control" formControlName="jiraProjectKey">
                            <option value="ON">ON</option>
                            <option value="SIEMENS">SIEMENS</option>
                            <option value="TEST">TEST</option>
                        </select>
                        <div class="error" *ngIf="hasFieldGotErrors('jiraProjectKey')">
                            <p *ngIf="clientForm.get('jiraProjectKey').errors.required">
                                {{ 'errors.errors_require' | translate }}
                            </p>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="currentTab === 'notification'">
                <div class="form-group row">
                    <label class="col-2 col-form-label"
                        >{{ 'clients.add_new_component.languageInEmails' | translate }}
                        <i class="fa fa-info-circle" placement="right" [tooltip]="'clients.add_new_component.language_tooltip' | translate"></i>
                    </label>
                    <div class="col-6">
                        <select class="form-control" formControlName="language">
                            <option value="de">
                                {{ 'clients.add_new_component.german' | translate }}
                            </option>
                            <option value="en">
                                {{ 'clients.add_new_component.english' | translate }}
                            </option>
                        </select>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-2 col-form-label"
                        >{{ 'clients.add_new_component.salutation' | translate }}&nbsp;<i
                            class="fa fa-info-circle"
                            placement="right"
                            [tooltip]="'clients.add_new_component.contact_salutation_tooltip' | translate"
                        ></i
                    ></label>
                    <div class="col-6">
                        <input class="form-control" type="text" formControlName="contactSalutation" />
                        <div class="error" *ngIf="hasFieldGotErrors('contactSalutation')">
                            <p *ngIf="clientForm.get('contactSalutation').errors.required">
                                {{ 'errors.errors_require' | translate }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-2 col-form-label"
                        >{{ 'clients.add_new_component.contact_name' | translate }}&nbsp;<i
                            class="fa fa-info-circle"
                            placement="right"
                            [tooltip]="'clients.add_new_component.contact_name_tooltip' | translate"
                        ></i
                    ></label>
                    <div class="col-6">
                        <input class="form-control" type="text" formControlName="contactName" />
                        <div class="error" *ngIf="hasFieldGotErrors('contactName')">
                            <p *ngIf="clientForm.get('contactName').errors.required">
                                {{ 'errors.errors_require' | translate }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-2 col-form-label"
                        >{{ 'clients.add_new_component.contact_e-mail' | translate }}&nbsp;<i
                            class="fa fa-info-circle"
                            placement="right"
                            [tooltip]="'clients.add_new_component.contact_email_tooltip' | translate"
                        ></i
                    ></label>
                    <div class="col-6 email-control" formArrayName="contactEmail">
                        <div *ngFor="let email of clientForm.get('contactEmail').controls; let i = index">
                            <div [formGroupName]="i">
                                <input class="form-control" type="email" formControlName="address" />
                                <div class="text-center is-active-wrap">
                                    <div class="small">Is Primary</div>
                                    <input
                                        type="radio"
                                        [value]="i"
                                        (click)="onSetPrimaryEmail(clientForm.get('contactEmail').controls, i)"
                                        [formControl]="clientForm.get('primaryContactEmail')"
                                    />
                                </div>
                                <div class="text-center is-active-wrap">
                                    <div class="small">Is Active</div>
                                    <input type="checkbox" formControlName="isActive" />
                                </div>
                                <button class="btn btn-danger" (click)="removeFormArrayItem(clientForm, 'contactEmail', i)">-</button>
                                <button class="btn btn-success" *ngIf="i == 0" (click)="addFormEmailItem(clientForm, 'contactEmail')">+</button>
                                <div class="error" *ngIf="email.touched && email.invalid">
                                    <p *ngIf="email.controls.address.errors.required">
                                        {{ 'errors.errors_require' | translate }}
                                    </p>
                                    <p *ngIf="email.controls.address.errors.email">
                                        {{ 'errors.invalid_email' | translate }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="currentTab === 'onboarding'">
                <div class="form-group row">
                    <label class="col-2 col-form-label"
                        >{{ 'clients.add_new_component.upload_documents_limit' | translate }}
                        <i class="fa fa-info-circle" placement="right" [tooltip]="'clients.add_new_component.document_limit_tooltip' | translate"></i>
                    </label>
                    <div class="col-6">
                        <input class="form-control" type="number" min="1" step="1" formControlName="uploadDocumentsCount" />
                    </div>
                </div>

                <div class="form-group row">
                    <label for="prediction-accepted" class="col-2 col-form-label">
                        <span>{{ 'clients.enable_auto_predictions' | translate }}</span>
                        <i class="fa fa-info-circle" placement="right" [tooltip]="'clients.add_new_component.enable_predictions_tooltip' | translate"></i>
                    </label>
                    <div class="col-6">
                        <input type="checkbox" style="margin-top: 11px" id="prediction-accepted" formControlName="predictionAccepted" />
                    </div>
                </div>

                <div *ngIf="clientForm.get('useAdminPortal').value" class="form-group row">
                    <label class="col-2 col-form-label">
                        <span>{{ 'clients.enable_basic_connections' | translate }}</span>
                        <i class="fa fa-info-circle" placement="right" [tooltip]="'clients.add_new_component.enable_basic_connections_tooltip' | translate"></i>
                    </label>
                    <div class="col-6">
                        <input
                            type="checkbox"
                            [attr.disabled]="clientForm.get('useAdminPortal').value ? null : 'disabled'"
                            style="margin-top: 11px"
                            id="prediction-accepted"
                            formControlName="enableBasicConnections"
                            disabled
                        />
                    </div>
                </div>
            </ng-container>
        </div>
    </div>

    <div class="modal-footer">
        <button class="btn" type="button" id="client-cancel" (click)="cancel()">{{ 'modal.cancel' | translate }}</button>

        <ng-container *ngIf="currentTab !== 'onboarding'">
            <button class="btn" class="btn btn-primary-outline" (click)="continue()">
                {{ 'modal.continue' | translate }}
            </button>
        </ng-container>

        <ng-container>
            <button class="btn" *ngIf="!isEdit; else editBtnName" type="submit" class="btn btn-primary" id="client-add" (click)="submitForm(clientForm)">
                {{ 'modal.add' | translate }}
            </button>
            <ng-template #editBtnName>
                <button class="btn" type="submit" class="btn btn-primary" id="client-edit" (click)="submitForm(clientForm)">
                    {{ 'modal.apply' | translate }}
                </button>
            </ng-template>
        </ng-container>
    </div>
</ng-template>

<ng-template #deleteModal let-deleteModalRef>
    <div class="modal-header">
        <p class="modal-title">
            <b>{{ 'clients.delete.delete_warning' | translate }}</b>
        </p>
    </div>
    <div class="modal-body">
        <p>{{ 'clients.delete.delete_attachment' | translate : { value: deletedFileName } }}</p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" ngbAutofocus id="client-file-delete-attachment-close" (click)="deleteModalRef.close()">{{ 'modal.cancel' | translate }}</button>
        <button type="button" class="btn btn-primary" id="client-file-delete-attachment" (click)="onDeleteFile()">{{ 'options.delete' | translate }}</button>
    </div>
</ng-template>
