<ng-template #modal let-modalRef>
    <div class="modal-header">
        <p class="modal-title" ngbAutofocus>
            <b>{{'doctype.invalid_modal_header' | translate}}</b>
        </p>
    </div>
    <div class="modal-body">
        <p>{{'doctype.invalid_message' | translate}}</p>
        <ul *ngFor="let error of invalidAttributesList">
            <li>{{error}}</li>
        </ul>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" (click)="modalRef.close()">{{'modal.cancel' | translate}}</button>
    </div>
</ng-template>
