import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfigService } from '../../services/config.service';
import { CustomerModel } from '../../models/CustomerModel';
import { AddEditModalComponent } from './add-edit-modal/add-edit-modal.component';
import { MailTemplateModel } from '../../models/MailTemplateModel';
import { MailTemplateModalComponent } from './view-edit-mail-template/mail-template.component';
import { ClientModel } from '../../models/ClientModel';
import { EditClientFormModel } from '../../models/EditClientFormModel';
import { ClientDatabaseService } from '../../services/client-database.service';
import { MailTemplateService } from '../../services/mail-template-service';
import { map, takeUntil } from 'rxjs/operators';
import { AppDeleteModal } from './delete-modal/app-delete-modal';
import { Subject } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { SpinnerService } from '../../services/spinner.service';

export enum sotringListEnum {
    buyer = 'name'
}

@Component({
    selector: 'app-clients',
    templateUrl: './clients.component.html',
    styleUrls: ['./clients.component.scss']
})
export class ClientsComponent implements OnDestroy, OnInit {
    customers: CustomerModel[];
    mailTemplates: MailTemplateModel[];
    token: string;
    private unsubscribe: Subject<void> = new Subject();

    @ViewChild('appAddNew')
    addEditModal: AddEditModalComponent;
    @ViewChild('mailTemplate')
    mailTemplateModal: MailTemplateModalComponent;
    @ViewChild('deleteModal')
    deleteModal: AppDeleteModal;
    @ViewChild('tokenModal')
    tokenModal: ElementRef;
    sortingList = sotringListEnum;
    selectedField: string = this.sortingList.buyer;
    isAsc = true;

    constructor(
        private modalService: NgbModal,
        private configService: ConfigService,
        protected clientService: ClientDatabaseService,
        protected mailTemplateRepository: MailTemplateService,
        private route: ActivatedRoute,
        private spinnerService: SpinnerService
    ) {}

    public getCustomers() {
        this.configService.getCustomers(this.selectedField, this.isAsc).subscribe((customers: any) => {
            this.customers = customers;
        });
    }

    onSort(sortingField: string) {
        if (sortingField == this.selectedField) {
            this.isAsc = !this.isAsc;
        } else {
            this.isAsc = true;
            this.selectedField = sortingField;
        }
        this.getCustomers();
    }

    getSortClass(sortingField) {
        if (sortingField != this.selectedField) {
            return '';
        }
        return this.isAsc ? 'asc-sort' : 'desc-sort';
    }

    onAddClientCustomer() {
        if (this.mailTemplates) {
            this.addEditModal.onShowModal();
        } else {
            this.getMailTemplates()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((data) => this.addEditModal.onShowModal());
        }
    }

    onDeleteClient(customer: CustomerModel, client: ClientModel) {
        this.deleteModal.onShowModal(customer, client);
    }

    public getMailTemplates() {
        return this.mailTemplateRepository.getList().pipe(map((data) => (this.mailTemplates = data)));
    }

    onEditClient(customer: CustomerModel, client: ClientModel) {
        if (this.mailTemplates) {
            this.editClient(customer, client);
        } else {
            this.spinnerService.showSpinner();
            this.getMailTemplates()
                .pipe(takeUntil(this.unsubscribe))
                .subscribe((data) => {
                    this.editClient(customer, client);
                });
        }
    }

    editClient(customer: CustomerModel, client: ClientModel) {
        this.spinnerService.showSpinner();
        this.clientService
            .findById(client._id)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((client) => {
                this.spinnerService.hideSpinner();
                this.addEditModal.onShowModal(new EditClientFormModel(customer, client));
            });
    }

    showTemplatesModal(templatesList: MailTemplateModel[]) {
        this.mailTemplateModal.showModal(templatesList);
    }

    onShowToken(client: ClientModel) {
        this.token = client.token;
        this.modalService.open(this.tokenModal);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }

    ngOnInit(): void {
        this.customers = this.route.snapshot.data.clients;
    }
}
