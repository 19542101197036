<header id="first">
    <div class="header-content">
        <div class="inner">
            <div id="login-form">
                <!-- <form [formGroup]="authForm" (ngSubmit)="login(authForm)"> -->
                    <!-- <div class="form-group row welcome-title"> -->
                    <div class="welcome-title">
                        <h2>{{ 'auth.welcome' | translate }}</h2>
                        <hr class="extrasmall">
                        <h5 class="wow fadeIn text-normal">{{ 'auth.login_message' | translate }}</h5>
                        <br>
                    </div>
                    <hr class="extrasmall">
                    <div class="form-group">
                        <div class="login-btn-wrap">
                            <a class="btn btn-primary" href="{{hubLink}}">{{'auth.hub_login' | translate}}</a>
                        </div>
                    </div>
                <!-- </form> -->
            </div>
        </div>
    </div>
</header>
