import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRoutesService } from './api-routes.service';

@Injectable({
    providedIn: 'root'
})
export class ErrorLogService {

    constructor( private http: HttpClient, private apiRoutesService: ApiRoutesService) { }

    loadErrors() {
        return this.http.get(this.apiRoutesService.ERROR_LOG);
    }
}
