import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {ClientModel} from '../../models/ClientModel';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {ConfigService} from '../../services/config.service';

@Injectable()
export class ClientsResolver implements Resolve<ClientModel[]> {

  constructor(
    private configService: ConfigService
  ) {

  }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ClientModel[]> | Promise<ClientModel[]> | ClientModel[] {
    return this.configService.getCustomers('name', true)
  }

}
