<div class="onboardings-list">
    <table class="table table-striped">
        <thead>
            <tr>
                <th>{{ 'onboardings-list.timestamp' | translate }}</th>
                <th>{{ 'onboardings-list.client' | translate }}</th>
                <th>{{ 'onboardings-list.businessPartner' | translate }}</th>
                <th>{{ 'onboardings-list.docType' | translate }}</th>
                <th>{{ 'onboardings-list.existingPartner' | translate }}</th>
                <th>{{ 'onboardings-list.jiraTicket' | translate }}</th>
                <th>{{ 'onboardings-list.savedDocs' | translate }}</th>
                <th>{{ 'onboardings-list.portalLogin' | translate }}</th>
                <th>{{ 'onboardings-list.webTransfer' | translate }}</th>
                <th>{{ 'onboardings-list.mailConfig' | translate }}</th>
                <th>{{ 'onboardings-list.emailSent' | translate }}</th>
                <th>{{ 'onboardings-list.adminPortalPartner' | translate }}</th>
                <th>{{ 'onboardings-list.adminPortalSpecification' | translate }}</th>
                <th>{{ 'onboardings-list.summary' | translate }}</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let ticket of tickets">
                <td>{{ ticket.createdAt | date: 'short':'':locale }}</td>
                <td>
                    {{ ticket.clientId.name }}
                </td>
                <td>
                    {{ ticket.supplierName }}
                    <br />
                    {{ ticket.supplierNumber }}
                    <br />
                    {{ ticket.supplierDomain }}
                </td>
                <td>
                    {{ ticket.docType }}
                </td>
                <td>
                    <span *ngIf="ticket.existingPartner">{{ 'onboardings-list.yes' | translate }}</span>
                    <span *ngIf="!ticket.existingPartner">{{ 'onboardings-list.no' | translate }}</span>
                </td>
                <td>
                    <span *ngIf="ticket.ticketCreated"
                        ><a target="_blank" href="https://netfira.atlassian.net/browse/{{ ticket.ticketId }}">{{ ticket.ticketId }}</a></span
                    >
                    <span *ngIf="!ticket.ticketCreated"><i class="fa fa-times"></i></span>
                </td>
                <td>
                    <span *ngIf="ticket.docsSaved"><i class="fa fa-check"></i></span>
                    <span *ngIf="!ticket.docsSaved"><i class="fa fa-times"></i></span>
                </td>
                <td>
                    <span *ngIf="ticket.loginCreated"><i class="fa fa-check"></i></span>
                    <span *ngIf="(ticket.existingPartner || ticket.clientId.useAdminPortal) && !ticket.loginCreated"><i class="fa fa-minus"></i></span>
                    <span *ngIf="!ticket.loginCreated && !ticket.existingPartner && !ticket.clientId.useAdminPortal"><i class="fa fa-times"></i></span>
                </td>
                <!-- Mail config  -->
                <td>
                    <span *ngIf="ticket.mcCreated"><i class="fa fa-check"></i></span>
                    <span *ngIf="ticket.existingPartner || ticket.clientId.useAdminPortal"><i class="fa fa-minus"></i></span>
                    <span *ngIf="!ticket.mcCreated && !ticket.existingPartner && !ticket.clientId.useAdminPortal"><i class="fa fa-times"></i></span>
                </td>
                <!-- Webtransfer  -->
                <td>
                    <span *ngIf="ticket.wtCreated"><i class="fa fa-check"></i></span>
                    <span *ngIf="ticket.existingPartner || ticket.clientId.useAdminPortal"><i class="fa fa-minus"></i></span>
                    <span *ngIf="!ticket.wtCreated && !ticket.existingPartner && !ticket.clientId.useAdminPortal"><i class="fa fa-times"></i></span>
                </td>
                <!-- Email  -->
                <td>
                    <span *ngIf="ticket.newOnboardingEmailSent"><i class="fa fa-check"></i></span>
                    <span *ngIf="!ticket.newOnboardingEmailSent"><i class="fa fa-times"></i></span>
                </td>
                <!-- Admin Portal partner-->
                <td>
                    <span *ngIf="!ticket.clientId.useAdminPortal"><i class="fa fa-minus"></i></span>
                    <span *ngIf="ticket.adminPortalPartnerId && ticket.adminPortalClientPartnerId"><i class="fa fa-check"></i><br/>{{ ticket.adminPortalPartnerId }}</span>
                    <span *ngIf="ticket.adminPortalPartnerId && !ticket.adminPortalClientPartnerId && !ticket.existingPartner">
                        <i class="fa fa-exclamation-triangle" title="Partner {{ ticket.adminPortalPartnerId }} was created in Admin Portal but could not be assigned to customer system! Please assign manually"></i>
                        <br/>
                        {{ ticket.adminPortalPartnerId }}
                    </span>
                    <span *ngIf="!ticket.existingPartner && ticket.clientId.useAdminPortal && !ticket.adminPortalPartnerId"><i class="fa fa-times"></i></span>
                </td>
                <!-- Admin Portal specification -->
                <td>
                    <span *ngIf="!ticket.clientId.useAdminPortal"><i class="fa fa-minus"></i></span>
                    <span *ngIf="ticket.adminPortalSpecificationId">
                        <i class="fa fa-check"></i>
                        <br/>
                        {{ ticket.adminPortalSpecificationId }}
                </span>
                    <span *ngIf="ticket.clientId.useAdminPortal && !ticket.adminPortalSpecificationId">{{ 'onboardings-list.specPendingValidation' | translate }}</span>
                </td>
                <td>
                    <span *ngIf="ticket.ticketDescription">
                        <button class="btn btn-link" (click)="showTicketDescriptionModal(ticket.ticketDescription)">
                            <i class="fa fa-external-link"></i>
                        </button>
                    </span>
                    <span *ngIf="!ticket.ticketDescription"><i class="fa fa-times"></i></span>
                </td>
            </tr>
        </tbody>
    </table>

    <app-paging *ngIf="totalPages" ($onPagingChanged)="onPagingChanged($event)" [totalPages]="totalPages" [currentPage]="currentPage"></app-paging>
</div>
