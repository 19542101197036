import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {JiraCustomFieldsModel} from '../models/JiraCustomFieldsModel';
import {ClientDatabaseService} from './client-database.service';
import {map, mergeMap, share, switchMap, tap} from 'rxjs/operators';
import {CustomerModel} from '../models/CustomerModel';
import {ClientModel} from '../models/ClientModel';
import {CustomerDatabaseService} from './customer-database.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalStorageService {

  constructor(
    protected clientDataService: ClientDatabaseService,
    protected customersDataService: CustomerDatabaseService
  ) {

  }

  protected $jiraCustomFields: BehaviorSubject<JiraCustomFieldsModel>;
  protected $customers: BehaviorSubject<CustomerModel[]>;
  protected $clients: BehaviorSubject<CustomerModel[]>;

  getJiraCustomFields(): Observable<JiraCustomFieldsModel> {
    if (!this.$jiraCustomFields) {
      return this.clientDataService.getJiraCustomFields().pipe(tap(data => {
        this.$jiraCustomFields = new BehaviorSubject<JiraCustomFieldsModel>(data);
      }), share());
    } else {
      return this.$jiraCustomFields.asObservable();
    }
  }

  setCustomers(customersList: CustomerModel[]) {
    if (!this.$customers) {
      this.$customers = new BehaviorSubject<CustomerModel[]>(customersList);
    } else {
      this.$customers.next(customersList);
    }
  }

  getClientsList(): Observable<ClientModel[]> {
    if (!this.$clients) {
      return this.getCustomersList().pipe(map(customers => {
        let clientsList = [];
        customers.forEach(customer => {
          clientsList = clientsList.concat(customer.clients);
        });
        this.$clients = new BehaviorSubject<CustomerModel[]>(clientsList);
        return clientsList;
      }));
    } else {
      return this.$clients.asObservable();
    }
  }

  getCustomersList(): Observable<CustomerModel[]> {
    if (!this.$customers) {
      return this.customersDataService.getList().pipe(
        tap(list => this.$customers = new BehaviorSubject<CustomerModel[]>(list))
      );
    } else {
      return this.$customers.asObservable();
    }
  }

}
