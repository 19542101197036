import {Component, ViewChild} from '@angular/core';
import {MailTemplateService} from '../../services/mail-template-service';
import {MailTemplateModel} from '../../models/MailTemplateModel';
import {EditMailTemplateModalComponent} from './edit-mail-template-modal/edit-mail-template-modal.component';
import {ActivatedRoute} from '@angular/router';
import {catchError} from 'rxjs/operators';
import {of} from 'rxjs';

export enum sotringListEnum {
  name = 'displayName'
}

@Component({
  selector: 'app-mail-template',
  templateUrl: 'mail-templates.component.html',
  styleUrls: ['./mail-templates.component.scss']
})
export class MailTemplatesComponent {

  mailTemplateList: MailTemplateModel[];
  @ViewChild('editModalTemplate') editTemplateModal: EditMailTemplateModalComponent;
  sortingList = sotringListEnum;
  selectedField: string = this.sortingList.name;
  isAsc = true;

  constructor(
    protected mailTemplateService: MailTemplateService,
    private router: ActivatedRoute
  ) {
    this.mailTemplateList = this.router.snapshot.data.mailTemplates;
  }

  private getList() {
    this.mailTemplateService.getList(this.selectedField, this.isAsc).pipe(catchError(err => of([]))).subscribe(data => this.mailTemplateList = data);
  }

  onSort(sortingField: string) {
    if (sortingField == this.selectedField) {
      this.isAsc = !this.isAsc;
    } else {
      this.isAsc = true;
      this.selectedField = sortingField;
    }
    this.getList();
  }

  getSortClass(sortingField) {
    if (sortingField != this.selectedField) {
      return '';
    }
    return (this.isAsc) ? 'asc-sort' : 'desc-sort';
  }

  onEditMailTemplate(mailTemplate: MailTemplateModel) {
    this.editTemplateModal.showModal(mailTemplate);
  }

  onAddMailTemplate() {
    this.editTemplateModal.showModal();
  }

  ouUpdateMailTemplate(mailTemplate?: MailTemplateModel) {
    if (!!mailTemplate) {
      this.mailTemplateService.update(mailTemplate._id, mailTemplate).subscribe(data => {
        this.getList();
      });
    } else {
      this.getList();
    }
  }

}
