/* tslint:disable:no-debugger */
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, tap} from 'rxjs/operators';

import {ApiRoutesService} from './api-routes.service';
import {CustomerModel} from '../models/CustomerModel';
import {GlobalStorageService} from './global-storage.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private currentConfigSubject: BehaviorSubject<object>;

  constructor(
    private http: HttpClient,
    private apiRoutesService: ApiRoutesService,
    private globalStorageService: GlobalStorageService
  ) {
    this.currentConfigSubject = new BehaviorSubject<object>(null);
  }

  public getCustomers(sortBy: string, isAsc: boolean): Observable<CustomerModel[]> {
    return this.http.get<CustomerModel[]>(this.apiRoutesService.CUSTOMERS, {
      params: {
        sortBy: sortBy,
        order: (isAsc) ? 'asc' : 'desc'
      }
    }).pipe(tap(list => {
      this.globalStorageService.setCustomers(list);
    }), catchError(err => {
      return of([]);
    }));
  }

  public currentConfigValue(): object {
    return this.currentConfigSubject.value;
  }
}
