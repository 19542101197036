import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {ApiRoutesService} from "./api-routes.service";
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class AttachmentsDatabaseService {

    private url: string;

    constructor(
        protected http: HttpClient,
        protected apiRoutesService: ApiRoutesService) {
        this.url = this.apiRoutesService.ATTACHMENTS;
    }

    uploadFile(cId: string, attachment: File): Observable<any> {
        const data = new FormData();
        data.append('clientId', cId);
        data.append('file', attachment);
        return this.http.post(this.url, data);
    }

    getClientFiles(clientId: string) {
        return this.http.get(this.url, {params: {clientId: clientId}});
    }

    deleteFile(clientId: string, fileName: string) {
        return this.http.delete(this.url, {params: {clientId: clientId, fileName: fileName}});
    }
}