import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiRoutesService } from './api-routes.service';

@Injectable({
    providedIn: 'root'
})
export class OnboardingsListService {

    constructor( private http: HttpClient, private apiRoutesService: ApiRoutesService) { }

    loadOnboardings(skip, limit): any {
        return this.http.get(this.apiRoutesService.ONBOARDINGS_LIST, { params: { skip, limit }});
    }

}
