import {Injectable} from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler,
  HttpRequest, HttpErrorResponse
} from '@angular/common/http';

import {throwError, Observable, of} from 'rxjs';
import {catchError} from 'rxjs/operators';

import {AuthService} from '../services/auth.service';
import {GlobalErrorHandler} from '../services/global-error-handler.service';
import {Router} from "@angular/router";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  private AUTH_HEADER = 'Authorization';

  constructor(
    private authService: AuthService,
    protected router: Router
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const isFormData = req.body instanceof FormData;
    if (!isFormData && !req.headers.has('Content-Type')) {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json')
      });
    }

    req = this.addAuthenticationToken(req);

    return next.handle(req)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error && error.status === 401) {
            // 401 errors are most likely going to be because we have an expired token that we need to refresh.
            this.authService.logout();
            this.router.navigate(['']);
            return of(null);
          } else {
            return throwError(error);
          }
        })
      );
  }

  private addAuthenticationToken(request: HttpRequest<any>): HttpRequest<any> {
    // If we do not have a token yet then we should not set the header.
    // Here we could first retrieve the token from where we store it.
    const jwt = this.authService.getJWT();
    if (!jwt) {
      return request;
    }
    return request.clone({
      headers: request.headers.set(this.AUTH_HEADER, 'Bearer ' + jwt)
    });
  }

}
