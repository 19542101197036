<nav aria-label="Page navigation example">
    <ul class="pagination justify-content-center">
        <!-- <li class="page-item" [ngClass]="(currentPage == 1 || totalPages <= totalLinks) ? 'disabled': ''">
            <a class="page-link" data-href="" (click)="onPageChanged(1)" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
            </a>
        </li> -->
        <li class="page-item" *ngIf="!(currentPage == 1 || totalPages <= totalLinks)">
            <a class="page-link" data-href="" (click)="onPageChanged(1)" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
            </a>
        </li>

        <!-- <li class="page-item" *ngFor="let link of links" [ngClass]="(link.active)? 'active' : ''">
            <a class="page-link" *ngIf="!link.active; else active" data-href=""
               (click)="onPageChanged(link.page)">
                {{link.page}}
            </a>
            <ng-template #active>
                <span class="page-link">
                     {{link.page}}
                    <span class="sr-only">(current)</span>
                </span>
            </ng-template>
        </li> -->

        <li class="page-item" *ngFor="let link of links">
            <a class="page-link" *ngIf="!link.active; else active" data-href=""
               (click)="onPageChanged(link.page)">
                {{link.page}}
            </a>
            <ng-template #active>
                <span class="page-link">
                     {{link.page}}
                    <span class="sr-only">(current)</span>
                </span>
            </ng-template>
        </li>


        <!-- <li class="page-item" [ngClass]="(currentPage == totalPages || totalPages <= totalLinks) ? 'disabled': ''">
            <a class="page-link" data-href="" (click)="onPageChanged(totalPages)" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
            </a>
        </li> -->

        <li class="page-item" *ngIf="!(currentPage == totalPages || totalPages <= totalLinks)">
            <a class="page-link" data-href="" (click)="onPageChanged(totalPages)" aria-label="Next">
                <span aria-hidden="true">&raquo;</span>
            </a>
        </li>
    </ul>
</nav>
