<ng-template #deleteModal let-deleteModal>
    <div class="modal-header">
        <h4 class="modal-title"
            ngbAutofocus
            id="modal-basic-title">
            {{'options.delete'| translate}}
            <span *ngIf="isDeleteClient; else buyerTitle">
                {{'clients.client' | translate}}
            </span>
            <ng-template #buyerTitle>
                <span>
                    {{'clients.buyer' | translate}}
                </span>
            </ng-template>
        </h4>
    </div>
    <div class="modal-body">
        <p *ngIf="isDeleteClient; else buyer">
            {{'clients.delete.delete_client' | translate:{value: this.client.name} }}
        </p>
        <ng-template #buyer>
            <p>
                {{'clients.delete.delete_buyer' | translate:{value: this.customer.name} }}
            </p>
        </ng-template>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" (click)="deleteModal.close()">{{'modal.cancel' | translate}}</button>
        <button type="button" class="btn btn-primary" (click)="onDelete()">{{'options.delete' | translate}}</button>
    </div>
</ng-template>