import {ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Output, ViewChild} from '@angular/core';
import {AddNewMailTemplateModel, MailTemplateModel} from '../../../models/MailTemplateModel';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MailTemplateService} from '../../../services/mail-template-service';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';

@Component({
  selector: 'app-edit-mail-template-modal',
  templateUrl: './edit-mail-template-modal.component.html',
  styleUrls: ['./edit-mail-template-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditMailTemplateModalComponent {

  editMailTemplateForm: FormGroup;

  @ViewChild('mailTemplateRef') mailTemplateRef;
  protected mailTemplate: MailTemplateModel;
  protected modalRef: NgbModalRef;
  @ViewChild('template') templateField: ElementRef;

  @Output('onUpdateMailTemplate') onUpdate: EventEmitter<MailTemplateModel> = new EventEmitter();

  isEdit = false;
  isFormSubmitted = false;

  jsonValidationMessage = '';

  constructor(
    protected modalService: NgbModal,
    protected mailTemplateService: MailTemplateService
  ) {
    this.editMailTemplateForm = new FormGroup({
      name: new FormControl('', Validators.required),
      template: new FormControl('', Validators.required),
    });
  }

  showModal(mailTemplate?: MailTemplateModel) {
    this.buildForm(mailTemplate);
    this.modalRef = this.modalService.open(this.mailTemplateRef, {ariaLabelledBy: 'modal-basic-title'});
  }

  buildForm(mailTemplate: MailTemplateModel) {
    this.isEdit = false;
    this.editMailTemplateForm.reset();
    if (!!mailTemplate) {
      this.isEdit = true;
      this.mailTemplate = mailTemplate;
      const template = JSON.parse('{' + this.mailTemplate.template + '}');
      this.editMailTemplateForm.get('name').setValue(this.mailTemplate.displayName);
      this.editMailTemplateForm.get('template').setValue(JSON.stringify(template, null, 3));
    }
  }

  onSubmit() {
    for (let key in this.editMailTemplateForm.controls) {
      this.editMailTemplateForm.controls[key].markAsTouched();
    }
    if (this.editMailTemplateForm.invalid) {
      return;
    }
    (this.isEdit) ? this.onChange() : this.onAdd();
  }

  onChange() {
    if (!this.validateTemplateJson()) {
      return;
    }
    const isChanged = (this.editMailTemplateForm.get('template').value != this.mailTemplate.template || this.mailTemplate.displayName != this.editMailTemplateForm.get('name').value);
    if (isChanged) {
      this.mailTemplate.template = this.replaceMailTemplateCharacters(this.editMailTemplateForm.get('template').value);
      this.mailTemplate.displayName = this.editMailTemplateForm.get('name').value;
      this.onUpdate.emit(this.mailTemplate);
    }
    this.modalRef.close();
  }

  onAdd() {
    if (!this.validateTemplateJson()) {
      return;
    }
    this.isFormSubmitted = true;
    const newMailTemlate: AddNewMailTemplateModel = {
        displayName: this.editMailTemplateForm.get('name').value,
        template: this.replaceMailTemplateCharacters(this.editMailTemplateForm.get('template').value)
      };
    this.mailTemplateService.add(newMailTemlate).pipe(catchError(err => {
      this.modalRef.close();
      this.isFormSubmitted = false;
      return throwError(err);
    })).subscribe(() => {
      this.modalRef.close();
      this.isFormSubmitted = false;
      this.onUpdate.emit();
    });
  }

  cancel() {
    this.modalService.dismissAll();
  }

  validateTemplateJson(): boolean {
    let template = this.replaceMailTemplateCharacters(this.editMailTemplateForm.get('template').value);
    template = '{' + template + '}';
    try {
      JSON.parse(template);
      this.editMailTemplateForm.get('template').setErrors({invalidJSON: false});
      return true;
    } catch (e) {
      this.editMailTemplateForm.get('template').setErrors({invalidJSON: true});
      this.jsonValidationMessage = e;
      return false;
    }
  }

  replaceMailTemplateCharacters(mailTemplate: string): string {
    const startRegExp = new RegExp(/^(\b)?{/);
    const endRegExp = new RegExp(/}(\b)?$/);
    mailTemplate = mailTemplate.replace(startRegExp, '');
    mailTemplate = mailTemplate.replace(endRegExp, '');
    return mailTemplate;
  }
}
