<ng-template #mailTemplateRef let-modal>
    <div class="modal-header" ngbAutofocus>
        <div>
            {{ 'clients.mail_templates_component.male_template' | translate }}
        </div>
    </div>
    <div class="modal-body">
        <div *ngFor="let template of templates">
            {{ 'clients.mail_templates_component.template_name' | translate }}: {{template.displayName}}
            <br>
            <br>
            <pre class="prettyjson" [innerHtml]="template.template | prettyjson:3"></pre>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn" type="button" (click)="cancel()">{{ 'modal.cancel' | translate }}</button>
    </div>
</ng-template>
