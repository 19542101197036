import { ClientContactModel, ClientModel } from './ClientModel';
import { CustomerModel } from './CustomerModel';
import { FormControl, Validators } from '@angular/forms';

export class EditClientFormModel {
    public customerName: string;
    public portal: string;
    public portalToken: string;
    public adminPortalSystemId: Number;
    public useAdminPortal: boolean;
    public ID: string;
    public clientName: string;
    public adminPortalClientId: Number;
    public mail: string;
    public mailPassword: string;
    public adminPortalMailboxId: Number;
    public language: 'de' | 'en';
    public jiraProjectKey: string;
    public configReceiver: string;
    public mailTemplates: any[];
    public contactSalutation: string;
    public contactName: string;
    public contactEmail: {
        address: string;
        active: boolean;
        primary: boolean;
    }[];
    public attrLimit: number;
    public aiPrediction: boolean;
    public specToolPrediction: boolean;
    public domain: string[];
    public hasPassword: boolean;
    public canLoginViaPortal: boolean;
    public portalAuthorization: boolean;
    public documentationLink: string;
    public uploadDocumentsCount: number;
    public autoOnboardingTarget: boolean;
    public predictionAccepted: boolean;
    public enableBasicConnections: boolean;
    private customer: CustomerModel;
    private client: ClientModel;

    constructor(customer: CustomerModel, client: ClientModel) {
        this.client = client;
        this.customer = customer;
        this.customerName = customer.name;
        this.portal = customer.portal;
        this.portalToken = customer.portalToken;
        this.adminPortalSystemId = customer.adminPortalSystemId;
        this.ID = client.id;
        this.clientName = client.name;
        this.language = client.language || 'de';
        this.jiraProjectKey = client.jiraProjectKey || 'ON';
        this.mail = client.mail;
        this.mailPassword = client.mailPassword;
        this.adminPortalClientId = client.adminPortalClientId;
        this.adminPortalMailboxId = client.adminPortalMailboxId;
        this.configReceiver = client.configReceiver;
        this.mailTemplates = client.mailTemplates;
        this.contactSalutation = client.contact.formOfAddress;
        this.contactEmail = client.contact.emails;
        this.contactName = client.contact.name;
        this.attrLimit = client.maxAttributes;
        this.aiPrediction = client.aiPrediction;
        this.specToolPrediction = client.specToolPrediction;
        this.useAdminPortal = client.useAdminPortal;
        this.domain = client.domains;
        this.hasPassword = client.hasPassword;
        this.portalAuthorization = client.portalAuthorization;
        this.canLoginViaPortal = client.canLoginViaPortal;
        this.documentationLink = client.clientDocumentationLink;
        this.uploadDocumentsCount = client.uploadDocumentsCount;
        this.autoOnboardingTarget = client.autoOnboardingTarget;
        this.predictionAccepted = client.predictionAccepted;
        this.enableBasicConnections = client.enableBasicConnections;
    }

    public getCustomer(): CustomerModel {
        return this.customer;
    }

    public getClient(): ClientModel {
        return this.client;
    }
}
