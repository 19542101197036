import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'app-ticket-description-modal',
	templateUrl: './ticket-description-modal.component.html',
	styleUrls: ['./ticket-description-modal.component.scss'],
})

export class TicketDescriptionModalComponent {
	 @Input() ticketDescription;

	constructor(public modal: NgbActiveModal) {}
}
