import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'jsonArr'
})
export class JsonArrPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return value.map((el: any) => {
      return el.displayName;
    }).join(', ');
  }

}
