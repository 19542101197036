<ng-template #mailTemplateRef let-modal>
    <div class="modal-header" ngbAutofocus>
        <div class="header-text">
            <span *ngIf="isEdit">{{'options.edit' | translate}}</span>
            <span *ngIf="!isEdit">{{'modal.add' | translate}}</span>
            {{ 'clients.mail_templates_component.male_template' | translate }}
        </div>
    </div>
    <div class="modal-body col-12" [formGroup]="editMailTemplateForm">
        <div class="form-group row">
            <label class="col-3 col-form-label">{{"global.name" | translate}}</label>
            <div class="col-9">
                <input class="form-control" type="text" formControlName="name">
                <div class="error"
                     *ngIf="editMailTemplateForm.get('name').touched && editMailTemplateForm.get('name').invalid">
                    <p *ngIf="editMailTemplateForm.get('name').errors.required">
                        {{'errors.errors_require' | translate}}
                    </p>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-3 col-form-label">{{"global.template" | translate}}</label>
            <div class="col-9">
                <textarea class="form-control" formControlName="template"></textarea>
                <div class="error"
                     *ngIf="editMailTemplateForm.get('template').touched && editMailTemplateForm.get('template').invalid">
                    <p *ngIf="editMailTemplateForm.get('template').errors.required">
                        {{'errors.errors_require' | translate}}
                    </p>
                    <p *ngIf="editMailTemplateForm.get('template').errors.invalidJSON">
                        {{jsonValidationMessage}}
                    </p>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn" type="button" id="mail-template-calcel" (click)="cancel()">{{ 'modal.cancel' | translate }}</button>
        <button class="btn btn-primary" id="mail-template-on-add" [disabled]="isFormSubmitted" type="button"
                (click)="onSubmit()">{{ 'modal.apply' | translate }}</button>
    </div>
</ng-template>
