import {Injectable, NgZone} from '@angular/core';
import {SnackbarService} from 'ngx-snackbar';
import {TranslateService} from '@ngx-translate/core';
import { ErrorLogService } from './error-log.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(
    private snackbarService: SnackbarService,
    private translate: TranslateService,
    protected ngZone: NgZone,
    private errorService: ErrorLogService
  ) {
  }

  private showPopup(message: string, type?: string, timeout?: number): void {
    this.ngZone.run(() => {
      this.snackbarService.add({
        msg: '<strong>' + message + '</strong>',
        timeout: (timeout) ? timeout : 30000,
        action: {
          text: 'X',
          onClick: (snack) => {
          },
        },
        customClass: type ? `snackbar-message s-m-${type}` : 'snackbar-message s-m-error'
      });
    });
  }

  public notify(msgCode?: string, type?: string, additionalMessage?: string, time?: number) {
    this.translate.get(msgCode ? msgCode : 'errors.default_error_message')
      .subscribe(
        translation => {
          if (additionalMessage) {
            this.showPopup(translation + '\n' + additionalMessage, type, time);
          } else {
            this.showPopup(translation, type, time);
          }
        },
        error => {
          this.showPopup('Oops! Something went wrong. Please try again later.');
        }
      );
  }

}
