import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ApiRoutesService} from './api-routes.service';
import {IHttpRepository} from "./IHttpRepository";
import {CustomerModel} from "../models/CustomerModel";
import {Observable, throwError} from "rxjs";
import {catchError, map} from "rxjs/operators";
import {of} from "rxjs/internal/observable/of";
import {NotificationService} from "./notification.service";

@Injectable({
    providedIn: 'root'
})
export class CustomerDatabaseService implements IHttpRepository<CustomerModel> {

    constructor(
        private http: HttpClient,
        private apiRoutesService: ApiRoutesService
    ) {
    }

    add(newCustomer: CustomerModel): Observable<CustomerModel> {
        return this.http.post<CustomerModel>(this.apiRoutesService.CUSTOMERS, newCustomer);
    }

    delete(id: string): Observable<any> {
        let url = this.apiRoutesService.CUSTOMERS + '/' + id;
        return this.http.delete(url);
    }

    findById(id: string): Observable<CustomerModel> {
        let url = this.apiRoutesService.CUSTOMERS + '/' + id;
        return this.http.get<CustomerModel>(url);
    }

    getEntityList(): Observable<{ [key: string]: CustomerModel }> {
        return this.http.get(this.apiRoutesService.CUSTOMERS).pipe(
            map((item: CustomerModel) => {
                return {[item._id]: item};
            })
        );
    }

    getList(): Observable<CustomerModel[]> {
        return this.http.get(this.apiRoutesService.CUSTOMERS).pipe(catchError(err => of(null)));
    }

    update(id: string, model: CustomerModel): Observable<any> {
        const url = this.apiRoutesService.CUSTOMERS + '/' + id;
        return this.http.patch(url, model);
    }
}
