import {Component, ViewChild, Input} from '@angular/core';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {MailTemplateModel} from '../../../models/MailTemplateModel';

@Component({
  selector: 'app-mail-template-modal',
  templateUrl: './mail-template.component.html',
  styleUrls: ['./mail-template.component.scss'],
})
export class MailTemplateModalComponent {

  @ViewChild('mailTemplateRef') mailTemplateRef;
  protected mailTemplates: MailTemplateModel[];
  templates: MailTemplateModel[];

  constructor(
    protected modalService: NgbModal,
  ) {
  }

  showModal(mailTemplates: MailTemplateModel[]) {
    this.templates = [];
    mailTemplates.forEach(template => {
      this.templates.push({
        template: JSON.parse('{' + template.template + '}'),
        displayName: template.displayName,
        _id: null
      });
    });
    this.modalService.open(this.mailTemplateRef, {ariaLabelledBy: 'modal-basic-title'});
  }

  cancel() {
    this.modalService.dismissAll();
  }

}
