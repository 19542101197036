import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {ClientModel} from '../../../models/ClientModel';
import {NgbModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import {DoctypeModel} from '../../../models/DoctypeModel';

@Component({
  selector: 'app-clone-doctype-modal',
  templateUrl: './clone-doctype-modal.component.html',
  styleUrls: ['./clone-doctype-modal.component.scss']
})
export class CloneDoctypeModalComponent {

  @Output('onSuccess')
  onSuccess: EventEmitter<DoctypeModel> = new EventEmitter();
  @Input('clientsHasDocType') clientsHasDocType: ClientModel[];
  @ViewChild('modal') modalRef: ElementRef;
  selectedClient: ClientModel;
  clonedDoctype: DoctypeModel;
  private modal: NgbModalRef;
  attributesLimit: number;

  constructor(protected modalService: NgbModal) {

  }

  onShowModal(client: ClientModel) {
    this.attributesLimit = client.maxAttributes;
    this.selectedClient = null;
    this.clonedDoctype = null;
    this.modal = this.modalService.open(this.modalRef, {backdrop: 'static', keyboard: false});
  }

  onClientChanged() {
    this.clonedDoctype = null;
  }

  onSave() {
    if (!this.selectedClient || !this.clonedDoctype) {
      this.modal.close();
      return;
    }
    this.onSuccess.emit(this.clonedDoctype);
    this.modal.close();
  }
}
