import {Injectable} from '@angular/core';
import {ApiRoutesService} from './api-routes.service';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminPortalService {

  constructor(
    protected routeService: ApiRoutesService,
    protected http: HttpClient) {
  }

  testAdminPortalConnection(): Observable<any> {
    const url = this.routeService.ADMIN_PORTAL + '/test';

    return this.http.get(url);
  }

  getAdminPortalSystem(systemId: string): Observable<any> {
    const url = this.routeService.ADMIN_PORTAL + '/system';
    const params = {
      systemId: systemId
    };
    return this.http.get(url, {params:params});
  }

  getAdminPortalClient(clientId: string): Observable<any> {
    const url = this.routeService.ADMIN_PORTAL + '/client';
    const params = {
      clientId: clientId
    };
    return this.http.get(url, {params:params});
  }

  getAdminPortalMailbox(mailboxId: string): Observable<any> {
    const url = this.routeService.ADMIN_PORTAL + '/mailbox';
    const params = {
      mailboxId: mailboxId
    };
    return this.http.get(url, {params:params});
  }

  postAdminPortalGraphQl(query: string): Observable<any> {
    const url = this.routeService.ADMIN_PORTAL + '/query';
    const data = {
      query: query,
    };
    return this.http.post(url, data);
  }
}
