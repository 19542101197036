import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiRoutesService {

  private readonly API_BASE_ROUTE: string;
  public readonly LOGIN: string;
  public readonly CUSTOMERS: string;
  public readonly DOCTYPE_VALIDATE: string;
  public readonly CLIENT: string;
  public readonly MAIL_TEMPLATES: string;
  public readonly I18N: string;
  public readonly ATTACHMENTS: string;
  public readonly PATH: string;
  public readonly PORTAL: string;
  public readonly ADMIN_PORTAL: string;
  public readonly HUB_LOGIN: string;
  public readonly ERROR_LOG: string;
  public readonly ONBOARDINGS_LIST: string;

  constructor() {
    this.API_BASE_ROUTE = environment.apiBaseUrl;
    this.ATTACHMENTS = `${this.API_BASE_ROUTE}/api/admin/attachments`;
    this.LOGIN = `${this.API_BASE_ROUTE}/api/admin/login`;
    this.CUSTOMERS = `${this.API_BASE_ROUTE}/api/admin/customers`;
    this.CLIENT = `${this.API_BASE_ROUTE}/api/admin/clients`;
    this.DOCTYPE_VALIDATE = `${this.API_BASE_ROUTE}/api/admin/validateDoctype`;
    this.MAIL_TEMPLATES = `${this.API_BASE_ROUTE}/api/admin/mail-templates`;
    this.PATH = `${this.API_BASE_ROUTE}/api/admin/paths`;
    this.I18N = `${this.API_BASE_ROUTE}/api/i18n`;
    this.PORTAL = `${this.API_BASE_ROUTE}/api/admin/portal`;
    this.ADMIN_PORTAL = `${this.API_BASE_ROUTE}/api/admin/adminPortal`;
    this.HUB_LOGIN = `${this.API_BASE_ROUTE}/auth/hub`;
    this.ERROR_LOG = `${this.API_BASE_ROUTE}/api/admin/error`;
    this.ONBOARDINGS_LIST = `${this.API_BASE_ROUTE}/api/admin/onboarding/list`;
  }
}
