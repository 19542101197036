import { Component, ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { AttributePath, DocTypeAttribute, DoctypeModel, OnUpdateAttributeEventData } from '../../../models/DoctypeModel';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef, NgbTypeahead, NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';
import { merge, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';
import { AttributePathDatabaseService } from '../../../services/attribute-path-database.service';

@Component({
    selector: 'add-edit-attribute-modal',
    templateUrl: './add-edit-attribute-modal.component.html',
    styleUrls: ['./add-edit-attribute-modal.component.scss']
})
export class AddEditAttributeModalComponent implements OnDestroy {
    attribute: DocTypeAttribute;
    attributeIndex: number;
    @Output('onSuccess')
    onSuccess: EventEmitter<OnUpdateAttributeEventData> = new EventEmitter();
    @ViewChild('pathInstance', { static: true }) pathInstance: NgbTypeahead;
    @ViewChild('modal')
    modalRef: ElementRef;
    attributeForm: FormGroup;
    modal: NgbModalRef;
    isEdit: boolean;
    attributeTypes: string[];
    attributePathList: AttributePath[];
    showSpinner: boolean;
    attributes: DocTypeAttribute[];
    pathFocus$ = new Subject<string>();
    defaultPathFocus$ = new Subject<string>();

    private unsubscribe: Subject<void> = new Subject();

    constructor(protected modalService: NgbModal, protected config: NgbTypeaheadConfig, protected pathRepository: AttributePathDatabaseService) {
        this.attributeTypes = ['Text', 'Date', 'Amount'];
        this.config.showHint = true;
    }

    onShowModal(docTypeName: string, doctypeVersion?: number, attributes?: DocTypeAttribute[], attribute?: DocTypeAttribute, index?: number) {
        this.isEdit = !!attribute;
        this.attributes = attributes;
        if (this.isEdit) {
            this.attribute = attribute;
            this.attributeIndex = index;
        }
        this.showSpinner = true;
        this.pathRepository
            .getPath(docTypeName, doctypeVersion)
            .pipe(
                catchError((err) => {
                    this.showSpinner = false;
                    if (err.status == 400) {
                        return of([]);
                    } else {
                        return throwError(err);
                    }
                })
            )
            .subscribe((data) => {
                this.attributePathList = data;
                this.showSpinner = false;
            });
        // Create form.
        this.attributeForm = new FormGroup({
            name: new FormControl(this.isEdit ? attribute.name : '', [
                Validators.required,
                this.checkUniqueAttributeName.bind(this),
                illegalCharacterInAttributeName,
                Validators.maxLength(30)
            ]),
            description: new FormControl(this.isEdit ? attribute.description : ''),
            path: new FormControl(this.isEdit ? attribute.path : '', [Validators.required, this.checkUniqueAttributePath.bind(this)]),
            defaultReference: new FormControl(this.isEdit && attribute.default ? attribute.default.reference : ''),
            onboardingComment: new FormControl(this.isEdit ? attribute.OnboardingComment : ''),
            type: new FormControl(this.isEdit ? attribute.type : this.attributeTypes[0], Validators.required),
            required: new FormControl(this.isEdit ? attribute.required : false),
            canStatic: new FormControl(this.isEdit ? attribute.canStatic : false),
            defaultStaticValue: new FormControl(this.isEdit && attribute.default ? attribute.default.static : '', quotesValidation),
            hidden: new FormControl(this.isEdit ? attribute.hidden : false)
        });
        // Manipulate form fields if Hidden checkbox is enabled
        if (this.attributeForm.get('hidden').value) {
            this.isHiddenField(true);
        }
        // Validation rules. Manipulate form fields depending on Hidden checkbox
        this.attributeForm
            .get('hidden')
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((val: boolean) => {
                this.isHiddenField(val);
            });
        // Validation rules. Manipulate form fields depending on Can Static checkbox
        this.attributeForm
            .get('canStatic')
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((val: boolean) => {
                this.isCanStaticField(val);
            });
        // Open modal window.
        this.modal = this.modalService.open(this.modalRef, { backdrop: 'static', keyboard: false });
    }

    // Manipulate form fields if Hidden checkbox is enabled or disabled
    private isHiddenField(val: boolean) {
        if (val) {
            this.attributeForm.addControl('defaultValue', new FormControl(this.isEdit ? this.attribute.defaultValue : ''));
            this.attributeForm.get('canStatic').disable();
            this.attributeForm.get('required').disable();
        } else {
            this.attributeForm.removeControl('defaultValue');
            this.attributeForm.get('canStatic').enable();
            this.attributeForm.get('required').enable();
        }
        this.attributeForm.get('canStatic').setValue(false);
        this.attributeForm.get('required').setValue(false);
    }

    // Manipulate attribute_referencingform fields if Can Static checkbox is enabled or disabled
    private isCanStaticField(val: boolean) {
        this.attributeForm.get('defaultReference').setValue(null);
        this.attributeForm.get('defaultStaticValue').setValue(null);
    }

    onSave() {
        for (let key in this.attributeForm.controls) {
            this.attributeForm.controls[key].markAsTouched();
        }
        if (this.attributeForm.invalid) {
            return;
        }
        this.attributeForm.get('name');
        this.attribute = {
            name: this.attributeForm.get('name').value,
            description: this.attributeForm.get('description').value,
            // set path depends of type
            path: typeof this.attributeForm.get('path').value == 'string' ? this.attributeForm.get('path').value : this.attributeForm.get('path').value.path,
            default: {
                // set default reference path depends of type
                reference:
                    typeof this.attributeForm.get('defaultReference').value == 'string'
                        ? this.attributeForm.get('defaultReference').value
                        : this.attributeForm.get('defaultReference').value
                        ? this.attributeForm.get('defaultReference').value.path
                        : null,
                static: this.attributeForm.get('defaultStaticValue').value
            },
            OnboardingComment: this.attributeForm.get('onboardingComment').value,
            type: this.attributeForm.get('type').value,
            required: this.attributeForm.get('required').value,
            canStatic: this.attributeForm.get('canStatic').value,
            hidden: this.attributeForm.get('hidden').value,
            defaultValue: this.attributeForm.get('defaultValue') ? this.attributeForm.get('defaultValue').value : null
        };
        if (!this.attribute.hidden) {
            this.attribute.defaultValue = null;
        }
        this.onSuccess.emit({ attribute: this.attribute, index: this.isEdit ? this.attributeIndex : -1 });
        this.modal.close();
    }

    checkUniqueAttributeName(control: FormControl) {
        let attributes = this.attributes;

        if (this.isEdit) {
            attributes = this.attributes.filter((attr, index) => {
                return index !== this.attributeIndex;
            });
        }

        const duplicateNamesAttributesArr = attributes.filter((attr) => {
            return control.value === attr.name;
        });

        return duplicateNamesAttributesArr.length > 0 ? { notUniqueName: true } : null;
    }

    checkUniqueAttributePath(control: FormControl) {
        let attributes = this.attributes;

        if (this.isEdit) {
            attributes = this.attributes.filter((attr, index) => {
                return index !== this.attributeIndex;
            });
        }

        const duplicatePathsAttributesArr = attributes.filter((attr) => {
            return control.value === attr.path;
        });

        return duplicatePathsAttributesArr.length > 0 ? { notUniqueName: true } : null;
    }

    pathAutocomplete(path$: Observable<any>) {
        const debouncedText$ = path$.pipe(debounceTime(200), distinctUntilChanged());
        return merge(debouncedText$, this.pathFocus$).pipe(
            map((p) => {
                return p == ''
                    ? this.attributePathList
                    : this.attributePathList.filter((path) => {
                          return path.path.toLowerCase().startsWith(p.toLowerCase());
                      });
            })
        );
    }

    defaultPathAutocomplete(path$: Observable<any>) {
        const debouncedText$ = path$.pipe(debounceTime(200), distinctUntilChanged());
        return merge(debouncedText$, this.defaultPathFocus$).pipe(
            map((p) => {
                return p == ''
                    ? this.attributePathList.filter((path) => {
                          return path.type == this.attributeForm.get('type').value;
                      })
                    : this.attributePathList.filter((path) => {
                          return path.type == this.attributeForm.get('type').value && path.path.toLowerCase().startsWith(p.toLowerCase());
                      });
            })
        );
    }

    pathFormatter = (path: AttributePath) => {
        if (typeof path == 'string') {
            return path;
        }
        return path.path;
    };

    ensureEndingSlash = (controlName: 'path' | 'defaultReference') => {
        const value = this.attributeForm.get(controlName).value;

        let path = value;

        if (value.path) {
            path = value.path;
        }

        if (value.defaultReference) {
            path = value.defaultReference;
        }

        path = path.replace(/\/*$/, '');

        if (path.toLowerCase() === 'items') {
            path += '/';
        }

        path += '/';

        this.attributeForm.get(controlName).setValue(path);
    };

    selectedItem(item: any) {
        this.attributeForm.get('type').setValue(item.item.type);
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}

function quotesValidation(controll: FormControl) {
    if (!!controll.value && (controll.value.includes('"') || controll.value.includes("'"))) {
        return { quotesValidation: true };
    }
    return null;
}

function illegalCharacterInAttributeName(controll: FormControl) {
    if (controll.value.includes('%')) {
        return { illegalCharacter: true };
    }
    return null;
}

function beforeDismiss(): Promise<any> {
    const o = new Promise((resolve, reject) => {
        debugger;
        reject();
    });
    return o;
}
