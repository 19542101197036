import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'auth-hub',
  templateUrl: './auth-hub.component.html'
})
export class AuthHubComponent {
  constructor(
    protected router: Router,
    protected authService: AuthService,
    protected http: HttpClient,
    protected route: ActivatedRoute) {
    this.route.queryParams.subscribe(params => {
      const token = params['token'];
      if (token) {
        this.authService.onLogin(token);
        this.router.navigate(['clients']);
      } else{
        this.router.navigate(['']);
      }
    });
  }
}
