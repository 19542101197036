import {ElementRef, Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SpinnerService {
  private isShowSpinner: boolean;

  private body: HTMLBodyElement = document.getElementsByTagName('body')[0];

  showSpinner() {
    if (this.isShowSpinner) {
      return;
    }
    this.isShowSpinner = true;
    const spinnerWrap = document.createElement('div');
    spinnerWrap.classList.add('spinner-block');
    const spinner = document.createElement('div');
    spinner.classList.add('spinner');
    spinnerWrap.appendChild(spinner);
    for (let i = 1; i <= 3; i++) {
      const bounce = document.createElement('div');
      bounce.classList.add('bounce' + i);
      spinner.appendChild(bounce);
    }
    this.body.appendChild(spinnerWrap);
    this.body.classList.add('show-spinner');
  }

  hideSpinner() {
    const spinnerWrap = this.body.getElementsByClassName('spinner-block')[0];
    if (spinnerWrap) {
      this.body.removeChild(spinnerWrap);
    }
    this.body.classList.remove('show-spinner');
    this.isShowSpinner = false;
  }

}
