import {ApiRoutesService} from './api-routes.service';
import {AuthService} from './auth.service';
import {GlobalErrorHandler} from './global-error-handler.service';
import {CustomerDatabaseService} from './customer-database.service';
import {MailTemplateService} from './mail-template-service';
import {ClientDatabaseService} from './client-database.service';
import {AttachmentsDatabaseService} from './attachments.database.service';
import {AttributePathDatabaseService} from './attribute-path-database.service';
import {PortalService} from './portal.service';
import {ClientsResolver} from '../components/clients/clients.resolver';
import {DoctypesResolver} from '../components/doctypes/doctypes.resolver';
import {MailTemplatesResolver} from '../components/mail-templates/mail-templates.resolver';

export const Services = [
  ApiRoutesService,
  AuthService,
  GlobalErrorHandler,
  CustomerDatabaseService,
  ClientDatabaseService,
  MailTemplateService,
  AttachmentsDatabaseService,
  AttributePathDatabaseService,
  PortalService,
  ClientsResolver,
  DoctypesResolver,
  MailTemplatesResolver
];
