import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {ApiRoutesService} from './api-routes.service';
import {Observable} from 'rxjs';
import {ClientModel} from '../models/ClientModel';
import {IHttpRepository} from './IHttpRepository';
import {AvailableDoctype, ValidateDocTypeRequestModel} from '../models/DoctypeModel';
import {JiraCustomFieldsModel} from '../models/JiraCustomFieldsModel';

@Injectable({
  providedIn: 'root'
})
export class ClientDatabaseService implements IHttpRepository<ClientModel> {

  constructor(
    private http: HttpClient,
    private apiRoutesService: ApiRoutesService
  ) {
  }

  add(newClient: ClientModel): Observable<ClientModel> {
    return this.http.post(this.apiRoutesService.CLIENT, newClient);
  }

  findById(id: string): Observable<ClientModel> {
    return this.http.get(this.apiRoutesService.CLIENT + '/' + id);
  }

  update(clientId, updatedClient: ClientModel): Observable<any> {
    const url = this.apiRoutesService.CLIENT + '/' + clientId;
    return this.http.patch(url, updatedClient);
  }

  delete(id: string): Observable<any> {
    const url = this.apiRoutesService.CLIENT + '/' + id;
    return this.http.delete(url);
  }

  getList(sortBy: string, isAsc: boolean): Observable<ClientModel[]> {
    return this.http.get<ClientModel[]>(this.apiRoutesService.CLIENT,{params: {
        sortBy: sortBy,
        order: (isAsc) ? 'asc' : 'desc'
      }});
  }

  getJiraCustomFields(): Observable<JiraCustomFieldsModel> {
    return this.http.get<JiraCustomFieldsModel>(this.apiRoutesService.CLIENT + '/jiraCustomFields');
  }

  validateDoctype(request: ValidateDocTypeRequestModel) {
    return this.http.post(this.apiRoutesService.DOCTYPE_VALIDATE, request);
  }

  getAvailableDoctypes(){
    return this.http.get<AvailableDoctype[]>(this.apiRoutesService.CLIENT + '/availableDocTypes');
  }
}
