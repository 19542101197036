import {Component} from '@angular/core';
import {Observable} from 'rxjs';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent{

  $showSpinner: Observable<boolean>;

  constructor() {
    registerLocaleData(localeDe);
  }
}
