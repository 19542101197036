import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {BehaviorSubject, Observable} from 'rxjs';
import {share, tap} from 'rxjs/operators';

import * as jwtDecode from 'jwt-decode';

import {ApiRoutesService} from './api-routes.service';

@Injectable({providedIn: 'root'})
export class AuthService {

  private readonly JWT_LOCALSTORAGE_KEY = 'JWT';
  private currentUserSubject: BehaviorSubject<object>;
  public currentUser: Observable<object>;

  constructor(
    private http: HttpClient,
    private apiRoutesService: ApiRoutesService,
  ) {
    const jwt = localStorage.getItem(this.JWT_LOCALSTORAGE_KEY);
    this.currentUserSubject = jwt ? new BehaviorSubject<object>(jwtDecode(jwt)) : new BehaviorSubject<object>(null);
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public getJWT = () => localStorage.getItem(this.JWT_LOCALSTORAGE_KEY);

  public currentUserValue(): object {
    return this.currentUserSubject.value;
  }

  public login({password}) {
    return this.http.post(this.apiRoutesService.LOGIN, {password})
      .pipe(
        tap((res: any) => {
          if (res.token) {
            this.onLogin(res.token);
          }
        }),
        share()
      );
  }

  public onLogin(token: string) {
    const user = jwtDecode(token);
    this.currentUserSubject.next(user);
    localStorage.setItem(this.JWT_LOCALSTORAGE_KEY, token);
  }

  public logout() {
    localStorage.removeItem(this.JWT_LOCALSTORAGE_KEY);
    this.currentUserSubject.next(null);
  }

}
