<div class="client-list-module">
    <div class="text-center">
        <button class="btn btn-primary add-new" (click)="onAddClientCustomer()">{{ 'clients.add_new' | translate }}</button>
    </div>
    <div class="responsive-table-block">
        <table class="table clients-list-table responsive-table sorting-table">
            <thead>
                <tr>
                    <th width="250" class="has-sorting" [ngClass]="getSortClass(sortingList.buyer)">
                        <span (click)="onSort(sortingList.buyer)">{{ 'clients.system' | translate }}</span>
                    </th>
                    <th>{{ 'clients.portal_header' | translate }}</th>
                    <th>{{ 'clients.client_id' | translate }}</th>
                    <th>{{ 'clients.client' | translate }}</th>
                    <th>{{ 'global.e-mail' | translate }}</th>
                    <th>{{ 'clients.mail_templates' | translate }}</th>
                    <th>{{ 'clients.add_new_component.use_admin_portal' | translate }}</th>
                    <th width="124"></th>
                </tr>
            </thead>
            <tbody *ngIf="customers && customers.length">
                <ng-container *ngFor="let customer of customers; index as i">
                    <tr *ngIf="customer.clients.length == 1" class="f">
                        <td>{{ customer.name }}</td>
                        <td><a href="{{ customer.portal }}" target="_blank">{{ 'clients.portal_link' | translate }}</a></td>
                        <td>{{ customer.clients[0].id }}</td>
                        <td>{{ customer.clients[0].name }}</td>
                        <td>
                            <a href="mailto:{{ customer.clients[0].mail }}">{{ customer.clients[0].mail }}</a>
                        </td>
                        <td>
                            <span *ngIf="customer.clients[0].mailTemplates" (click)="showTemplatesModal(customer.clients[0].mailTemplates)" class="like-a-link">{{
                                customer.clients[0].mailTemplates | jsonArr
                            }}</span>
                        </td>
                        <td>
                            <span *ngIf="customer.clients[0].useAdminPortal"><i class="fa fa-check"></i></span>
                            <span *ngIf="!customer.clients[0].useAdminPortal"><i class="fa fa-times"></i></span>
                        </td>
                        <td class="text-center options">
                            <i class="fa fa-pencil" id="client-on-edit" (click)="onEditClient(customer, customer.clients[0])" title="{{ 'options.edit' | translate }}"></i>
                            <i class="fa fa-trash" id="client-on-delete" (click)="onDeleteClient(customer, customer.clients[0])" title="{{ 'options.delete' | translate }}"></i>
                            <i class="fa fa-link" id="client-on-show-token" (click)="onShowToken(customer.clients[0])" title="{{ 'options.display_link' | translate }}"></i>
                        </td>
                    </tr>
                    <ng-container *ngIf="customer.clients.length > 1" class="s">
                        <ng-container *ngFor="let client of customer.clients; index as i">
                            <tr>
                                <td *ngIf="i<1" [attr.rowspan]="customer.clients.length">
                                    <div>{{ customer.name }}</div>
                                </td>
                                <td *ngIf="i<1" [attr.rowspan]="customer.clients.length">
                                    <div><a href="{{ customer.portal }}" target="_blank">{{ 'clients.portal_link' | translate }}</a></div>
                                </td>
                                <td>{{ client.id }}</td>
                                <td>{{ client.name }}</td>
                                <td>
                                    <a href="mailto:{{ client.mail }}">{{ client.mail }}</a>
                                </td>
                                <td>
                                    <span *ngIf="client.mailTemplates" (click)="showTemplatesModal(client.mailTemplates)" class="like-a-link mail-templates-link"
                                        >{{ client.mailTemplates | jsonArr }}
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="client.useAdminPortal"><i class="fa fa-check"></i></span>
                                    <span *ngIf="!client.useAdminPortal"><i class="fa fa-times"></i></span>
                                </td>
                                <td class="text-center options">
                                    <i class="fa fa-pencil" id="client-on-edit" (click)="onEditClient(customer, client)" title="{{ 'options.edit' | translate }}"></i>
                                    <i class="fa fa-trash" id="client-on-delete" (click)="onDeleteClient(customer, client)" title="{{ 'options.delete' | translate }}"></i>
                                    <i class="fa fa-link" id="client-on-show-token" (click)="onShowToken(client)" title="{{ 'options.display_link' | translate }}"></i>
                                </td>
                            </tr>   
                        </ng-container>
                    </ng-container>
                </ng-container>
            </tbody>
            <tbody *ngIf="!customers || customers.length == 0">
                <tr>
                    <td colspan="6" style="text-align: center">{{ 'clients.no_customers_found' | translate }}</td>
                </tr>
            </tbody>
        </table>

        <div class="text-center">
            <button class="btn btn-primary add-new" id="client-add" (click)="onAddClientCustomer()">{{ 'clients.add_new' | translate }}</button>
        </div>
    </div>
</div>

<app-add-edit-modal #appAddNew [addCustomers]="customers" [addMailTemplates]="mailTemplates" (onSuccess)="getCustomers()"></app-add-edit-modal>
<app-mail-template-modal #mailTemplate></app-mail-template-modal>

<app-delete-modal #deleteModal (onSuccess)="getCustomers()"></app-delete-modal>

<ng-template #tokenModal let-tokenModalLink>
    <div class="modal-header token-modal-header">
        <h4 class="modal-title">
            {{ 'options.display_link' | translate }}
        </h4>
    </div>
    <div class="modal-body token-modal-body">
        <label class="link-label">Client link</label>
        <div class="form-group row">
            <div class="input-group col-12 col-sm-8 col-md-10">
                <div class="input-group-prepend">
                    <i class="input-group-text fa fa-link"></i>
                </div>
                <input type="text" class="form-control" value="APP_URI/{{ token }}" readonly />
            </div>
            <div class="col-12 col-sm-4 col-md-2 text-right">
                <a class="btn btn-primary portal-link" title="{{ 'global.open' | translate }}" target="_blank" href="APP_URI/{{ token }}">
                    {{ 'global.open' | translate }}
                </a>
            </div>
        </div>
        <label class="link-label">Admin link</label>
        <div class="form-group row">
            <div class="input-group col-12 col-sm-8 col-md-10">
                <div class="input-group-prepend">
                    <i class="input-group-text fa fa-link"></i>
                </div>
                <input type="text" class="form-control" value="APP_URI/login/admin/{{ token }}" readonly />
            </div>
            <div class="col-12 col-sm-4 col-md-2 text-right">
                <a class="btn btn-primary portal-link" title="{{ 'global.open' | translate }}" target="_blank" href="APP_URI/login/admin/{{ token }}">
                    {{ 'global.open' | translate }}
                </a>
            </div>
        </div>
    </div>
    <div class="modal-footer token-modal-footer">
        <button type="button" ngbAutoFocus class="btn" if="token-modal-close" (click)="tokenModalLink.close()">{{ 'modal.cancel' | translate }}</button>
    </div>
</ng-template>
