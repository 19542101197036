import {Component, ElementRef, Input, ViewChild} from "@angular/core";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-validation-error',
  templateUrl: './validation-error-modal.component.html'
})
export class ValidationErrorModalComponent {
  @Input() invalidAttributesList: String[];
  @ViewChild('modal') modalRef: ElementRef;
  private modal: NgbModalRef;

  constructor(protected modalService: NgbModal) {

  }

  onShowModal() {
    this.modal = this.modalService.open(this.modalRef, {backdrop: 'static', keyboard: false});
  }


}
