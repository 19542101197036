import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';

import {environment} from '../../environments/environment';

import {ErrorService} from './error.service';
import {NotificationService} from './notification.service';

import { ErrorLogService } from './error-log.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {

  // Error handling is important and needs to be loaded first.
  // Because of this we should manually inject the services with Injector.
  constructor(private injector: Injector, private errorService: ErrorLogService) {
  }

  handleError(error: Error | HttpErrorResponse) {

    const errorService = this.injector.get(ErrorService);
    const notifier = this.injector.get(NotificationService);

    if (error instanceof HttpErrorResponse) {
      // Server Error
      if (!error.error.hiddenNotification) {
        notifier.notify(errorService.getServerMessage(error), 'error', (error.error.additionalMessage) ? error.error.additionalMessage : null);
      }
    } else {
      // Client Error
      notifier.notify(errorService.getClientMessage(error));
    }
    this.logError(error);
  }

  // Send logs.
  logError(error: any) {
    if (!environment.staging && !environment.production) {
      console.log('[ ERROR ]', error);
    }
  }

}
