<div class="doctipes-list-model">
    <div class="text-center">
        <button class="btn btn-primary add-new-btn" (click)="onAddDoctype()">{{ 'doctype.add_new' | translate }}</button>
    </div>
    <div class="responsive-table-block">
        <table class="table responsive-table overview-table sorting-table">
            <thead>
                <tr>
                    <th class="has-sorting" [ngClass]="getSortClass(sortingList.client)">
                        <span (click)="onSort(sortingList.client)">{{ 'doctype.client' | translate }}</span>
                    </th>
                    <th>{{ 'doctype.doctype' | translate }}</th>
                    <th>{{ 'doctype.display_name' | translate }}</th>
                    <th>{{ 'doctype.version' | translate }}</th>
                    <th>{{ 'doctype.comment' | translate }}</th>
                    <th class="text-center">{{ 'doctype.active' | translate }}</th>
                    <th width="124"></th>
                </tr>
            </thead>
            <tbody *ngIf="clientsHasDoctypes && clientsHasDoctypes.length > 0; else noDataFound">
                <ng-container *ngFor="let c of clientsHasDoctypes">
                    <ng-container *ngFor="let d of c.docTypes; index as i">
                        <tr>
                            <td class="customer-name" [attr.rowspan]="c.docTypes.length" *ngIf="i == 0">{{ c.name }}</td>
                            <td class="name">{{ d.name }}</td>
                            <td class="display-name">{{ d.displayName }}</td>
                            <td class="version">{{ d.version }}</td>
                            <td class="comment">{{ d.comment }}</td>
                            <td class="active text-center">
                                <input type="checkbox" [ngModel]="d.active" (click)="onChangeStatus(c, d)" />
                            </td>
                            <td align="center actions">
                                <span class="pointer action-link" title="{{ 'options.validate' | translate }}" (click)="onValidDoctype(d, c)">
                                    <i class="fa fa-refresh"></i>
                                </span>
                                <span class="pointer action-link" title="{{ 'options.edit' | translate }}" (click)="onEditDoctype(d)">
                                    <i class="fa fa-pencil"></i>
                                </span>
                                <span class="pointer action-link" title="{{ 'options.delete' | translate }}" (click)="showOnDeleteDocTypeModal(d, c)">
                                    <i class="fa fa-trash"></i>
                                </span>
                            </td>
                        </tr>
                    </ng-container>
                </ng-container>
            </tbody>
            <ng-template #noDataFound>
                <tbody>
                    <tr>
                        <td colspan="6" style="text-align: center">
                            {{ 'doctype.no_results' | translate }}
                        </td>
                    </tr>
                </tbody>
            </ng-template>
        </table>
    </div>
    <div class="text-center">
        <button class="btn btn-primary add-new-btn" (click)="onAddDoctype()">{{ 'doctype.add_new' | translate }}</button>
    </div>
</div>

<add-edit-doctype-modal #addEditDoctypeRef [clientsHasDocType]="clientsHasDoctypes" [clientsList]="clientList" (onSuccess)="onUpdateClient($event)"> </add-edit-doctype-modal>

<ng-template #deleteModal let-deleteModalRef>
    <div class="modal-header" ngbAutofocus>
        <p class="modal-title">
            <b>{{ 'doctype.delete_doctype_title' | translate }}</b>
        </p>
    </div>
    <div class="modal-body">
        <p>
            {{
                'doctype.delete_doctype'
                    | translate
                        : {
                              docTypeName: selectedDoctype.name,
                              clientName: selectedClient.name
                          }
            }}
        </p>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn" (click)="deleteModalRef.close()">{{ 'modal.cancel' | translate }}</button>
        <button type="button" class="btn btn-primary" (click)="onDeleteDoctype(selectedDoctype, selectedClient)">{{ 'options.delete' | translate }}</button>
    </div>
</ng-template>
<app-validation-error #validationErrorModal [invalidAttributesList]="validationErrorsAttributes"></app-validation-error>
