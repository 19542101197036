import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {MailTemplateModel} from '../../models/MailTemplateModel';
import {MailTemplateService} from '../../services/mail-template-service';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

@Injectable()
export class MailTemplatesResolver implements Resolve<MailTemplateModel[]> {

  constructor(private mailTemplateService: MailTemplateService) {

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<MailTemplateModel[]> | Promise<MailTemplateModel[]> | MailTemplateModel[] {
    return this.mailTemplateService.getList('displayName', true);
  }

}
