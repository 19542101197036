import {Component, OnDestroy} from '@angular/core';
import {NavigationEnd, Router, RouterEvent} from '@angular/router';

import {TranslateService} from '@ngx-translate/core';

import {AuthService} from '../../services/auth.service';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {environment} from '../../../environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnDestroy {

  public isCollapsed = true;
  public isAuth: boolean;
  public currentLangEng: boolean;
  public defaultLanguage: string;

  private unsubscribe: Subject<void> = new Subject();
  private currentUrl: string;
  supportedLanguages = ['en', 'de'];

  constructor(
    public router: Router,
    public authService: AuthService,
    private translate: TranslateService
  ) {
    this.authService.currentUser
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((user: object) => {
        this.isAuth = Boolean(user);
      });
    this.defaultLanguage = this.getUserPrefLang();
    this.currentLangEng = this.defaultLanguage === 'en';
    translate.setDefaultLang(this.defaultLanguage);
    translate.use(this.defaultLanguage);
    this.router.events.subscribe((d: RouterEvent) => {
      if (d instanceof NavigationEnd) {
        if (d.url === this.currentUrl) {
          window.scrollTo({top: 0, left: 0});
        }
        this.currentUrl = d.url;
      }
    });
  }
  private getUserPrefLang():string {
    if (navigator.languages != undefined)  {
      return navigator.languages[0].split('-')[0].toLowerCase();
    }
    const lang =  navigator.language.split('-')[0].toLowerCase();
    if (this.supportedLanguages.includes(lang)) {
      return lang;
    }
    return 'en';
  }
  public onChangeLang() {
    this.currentLangEng = !this.currentLangEng;
    if (this.currentLangEng) {
      this.translate.use('en');
    } else {
      this.translate.use('de');
    }
  }

  public logout() {
    this.authService.logout();
    this.router.navigate(['']);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
