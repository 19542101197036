import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {ClientsComponent} from './components/clients/clients.component';
import {DoctypesComponent} from './components/doctypes/doctypes.component';
import {AuthComponent} from './components/auth/auth.component';
import {AuthGuard} from './guards/auth.guard';
import {UnAuthGuard} from './guards/unAuth.guard';
import {MailTemplatesComponent} from './components/mail-templates/mail-templates.component';
import {ClientsResolver} from './components/clients/clients.resolver';
import {DoctypesResolver} from './components/doctypes/doctypes.resolver';
import {MailTemplatesResolver} from './components/mail-templates/mail-templates.resolver';
import {AuthHubComponent} from './components/auth/hub/auth-hub.component';
import {LogOutComponent} from './components/log-out/log-out.component';
import {ExceptionsLogComponent} from './components/exceptions-log/exceptions-log.component';
import {OnboardingsListComponent} from './components/onboardings-list/onboardings-list.component';

const routes: Routes = [
  {path: '', component: AuthComponent, canActivate: [UnAuthGuard]},
  {path: 'login', component: AuthHubComponent},
  {path: 'logout', component: LogOutComponent},
  {
    path: 'clients', component: ClientsComponent, canActivate: [AuthGuard], resolve: {
      clients: ClientsResolver
    }
  },
  {
    path: 'doctypes', component: DoctypesComponent, canActivate: [AuthGuard], resolve: {
      doctypes: DoctypesResolver
    }
  },
  {
    path: 'mail-templates', component: MailTemplatesComponent, canActivate: [AuthGuard], resolve: {
      mailTemplates: MailTemplatesResolver
    }
  },
  {path: 'onboardings-list', component: OnboardingsListComponent, canActivate: [AuthGuard]},
  {path: '**', redirectTo: ''}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule],
  providers: [AuthGuard]
})
export class AppRoutingModule {
}
