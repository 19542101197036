import {Component} from "@angular/core";
import {Router} from "@angular/router";
import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-log-out',
  template: ''
})
export class LogOutComponent {

  constructor(
    protected router: Router,
    protected authService: AuthService
  ) {
    this.authService.logout();
    this.router.navigate(['login']);
  }

}
