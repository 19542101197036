import { Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { ClientModel } from '../../models/ClientModel';
import { ClientDatabaseService } from '../../services/client-database.service';
import { AddEditDocTypesModalComponent } from './add-edit-doctypes-modal/add-edit-doctypes-modal.component';
import { DoctypeModel, ValidateDocTypeRequestModel } from '../../models/DoctypeModel';
import { NotificationService } from '../../services/notification.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { of, Subject, throwError } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { ValidationErrorModalComponent } from './validation-error-modal/validation-error-modal.component';
import { ActivatedRoute } from '@angular/router';

export enum sotringListEnum {
    client = 'name'
}

@Component({
    selector: 'app-doctypes',
    templateUrl: './doctypes.component.html',
    styleUrls: ['./doctypes.component.scss']
})
export class DoctypesComponent implements OnDestroy {
    clientList: ClientModel[];
    clientsHasDoctypes: ClientModel[];
    selectedDoctype: DoctypeModel;
    selectedClient: ClientModel;
    @ViewChild('addEditDoctypeRef') addEditModal: AddEditDocTypesModalComponent;
    @ViewChild('deleteModal') deleteModalRef: ElementRef;
    @ViewChild('validationErrorModal') validationErrorModal: ValidationErrorModalComponent;
    deleteModal: NgbModalRef;
    private unsubscribe: Subject<void> = new Subject();
    sortingList = sotringListEnum;
    selectedField: string = this.sortingList.client;
    isAsc = true;
    validationErrorsAttributes: String[];

    constructor(
        protected clientRepository: ClientDatabaseService,
        protected notificationService: NotificationService,
        protected modalService: NgbModal,
        private route: ActivatedRoute
    ) {
        this.clientList = this.route.snapshot.data.doctypes.clients;
        this.clientsHasDoctypes = this.route.snapshot.data.doctypes.docTypes;
    }

    getDoctypes() {
        this.clientRepository
            .getList(this.selectedField, this.isAsc)
            .pipe(
                takeUntil(this.unsubscribe),
                catchError((err) => {
                    return of([]);
                })
            )
            .subscribe((data) => {
                this.clientList = data;
                this.clientsHasDoctypes = data.filter((client: ClientModel) => {
                    if (!!client.docTypes && client.docTypes.length > 0) {
                        client.docTypes.forEach((docType: DoctypeModel) => {
                            docType.client = client._id;
                        });
                        return true;
                    }
                    return false;
                });
            });
    }

    onSort(sortingField: string) {
        if (sortingField == this.selectedField) {
            this.isAsc = !this.isAsc;
        } else {
            this.isAsc = true;
            this.selectedField = sortingField;
        }
        this.getDoctypes();
    }

    getSortClass(sortingField) {
        if (sortingField != this.selectedField) {
            return '';
        }
        return this.isAsc ? 'asc-sort' : 'desc-sort';
    }

    onAddDoctype() {
        this.addEditModal.onShowModal();
    }

    onEditDoctype(docType: DoctypeModel) {
        console.log(docType);
        this.addEditModal.onShowModal(docType);
    }

    onChangeStatus(client: ClientModel, docType: DoctypeModel) {
        docType.active = !docType.active;
        this.updateClient(client);
    }

    onDeleteDoctype(docType: DoctypeModel, client: ClientModel) {
        let updatedDoctypes = client.docTypes.filter((dt) => dt._id != docType._id);
        client.docTypes = updatedDoctypes;
        this.deleteModal.close();
        this.clientRepository
            .update(client._id, client)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe(
                (data) => {
                    this.getDoctypes();
                },
                (error) => {
                    this.notificationService.notify('Entry could not be deleted', 'error');
                }
            );
    }

    showOnDeleteDocTypeModal(docType: DoctypeModel, client: ClientModel) {
        this.selectedDoctype = docType;
        this.selectedClient = client;
        this.deleteModal = this.modalService.open(this.deleteModalRef);
    }

    onUpdateClient(event: Event) {
        this.updateClient(event as ClientModel);
    }

    onValidDoctype(docType: DoctypeModel, client: ClientModel) {
        const requestData: ValidateDocTypeRequestModel = {
            doctype: docType.name,
            version: docType.version,
            attributes: docType.attributes,
            clientId: client._id,
            doctypeId: docType._id
        };
        this.clientRepository
            .validateDoctype(requestData)
            .pipe(
                takeUntil(this.unsubscribe),
                catchError((error) => {
                    docType.valid = false;
                    if (error.error && error.error.invalidAttributes) {
                        this.validationErrorsAttributes = error.error.invalidAttributes;
                        this.validationErrorModal.onShowModal();
                        return of(null);
                    } else {
                        return throwError(error);
                    }
                })
            )
            .subscribe((resp) => {
                if (resp) {
                    docType.valid = true;
                }
            });
    }

    private updateClient(client: ClientModel) {
        this.clientRepository
            .update(client._id, client)
            .pipe(takeUntil(this.unsubscribe))
            .subscribe((data) => {
                this.getDoctypes();
            });
    }

    ngOnDestroy(): void {
        this.unsubscribe.next();
        this.unsubscribe.complete();
    }
}
