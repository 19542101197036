import {Injectable} from '@angular/core';
import {Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {CanActivate} from '@angular/router';

import {AuthService} from '../services/auth.service';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class UnAuthGuard implements CanActivate {

  path: any;
  route: any;

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const user = this.authService.currentUserValue();
    if (!user) {
      return true;
    } else {
      this.router.navigate(['clients']);
      return false;
    }
  }
}
