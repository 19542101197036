import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ClientModel } from '../../../models/ClientModel';
import { AttributePath, AvailableDoctype, DocTypeAttribute, DoctypeModel, OnUpdateAttributeEventData } from '../../../models/DoctypeModel';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AddEditAttributeModalComponent } from '../add-edit-attribute-modal/add-edit-attribute-modal.component';
import { NotificationService } from '../../../services/notification.service';
import { CloneDoctypeModalComponent } from '../clone-doctype-modal/clone-doctype-modal.component';
import { ClientDatabaseService } from '../../../services/client-database.service';
import { SpinnerService } from '../../../services/spinner.service';
import { AttributePathDatabaseService } from 'src/app/services/attribute-path-database.service';
import { merge, Observable, of, Subject, throwError } from 'rxjs';
import { catchError, tap, debounceTime, distinctUntilChanged, filter, map, takeUntil } from 'rxjs/operators';

const CSSClass = {
    PositionItem: 'position-item',
    HeadItem: 'head-item',
    SpecialItem: 'special-item'
};

@Component({
    selector: 'add-edit-doctype-modal',
    templateUrl: './add-edit-doctypes-modal.component.html',
    styleUrls: ['./add-edit-doctypes-modal.component.scss']
})
export class AddEditDocTypesModalComponent {
    @Input('clientsList') clientsList: ClientModel[];
    @Input('clientsHasDocType') clientsHasDocType: ClientModel[];
    @Output('onSuccess') onSuccess: EventEmitter<ClientModel> = new EventEmitter();
    isEdit: boolean;
    docType: DoctypeModel;
    docTypesList: DoctypeModel[];
    clientId: string;
    docTypeForm: FormGroup;
    @ViewChild('modal') modalRef: ElementRef;
    private modal: NgbModalRef;
    @ViewChild('addEditAttributeModal')
    addEditAttributeModal: AddEditAttributeModalComponent;
    @ViewChild('cloneDoctypeModal')
    cloneDoctypeModal: CloneDoctypeModalComponent;
    selectedAttribute: DocTypeAttribute;
    availableDoctypes: AvailableDoctype[];
    cloneDoctype: DoctypeModel;
    selectedDoctype: AvailableDoctype;
    selectedClient: ClientModel;

    @ViewChild('deleteModal') deleteModalRef;
    attributeForm: FormGroup;
    attributeTypes: string[] = ['Text', 'Date', 'Amount'];
    attributePathList: AttributePath[];
    private unsubscribe: Subject<void> = new Subject();
    editingAttribute: DocTypeAttribute;
    editingAttributeIndex: number;
    private deleteModal: NgbModalRef;
    attributeToDelete: DocTypeAttribute;
    attributeToDeleteIndex: number;
    showHiddenAttributes: boolean = true;

    pathFocus$ = new Subject<string>();
    defaultPathFocus$ = new Subject<string>();

    constructor(
        protected modalService: NgbModal,
        protected clientService: ClientDatabaseService,
        protected notificationService: NotificationService,
        private spinnerService: SpinnerService,
        protected pathRepository: AttributePathDatabaseService
    ) {}

    onShowModal(docType?: DoctypeModel) {
        this.spinnerService.showSpinner();

        this.isEdit = !!docType;

        if (this.isEdit) {
            this.docType = docType;
        }

        this.clientService
            .getAvailableDoctypes()
            .pipe(
                tap((d) => this.spinnerService.hideSpinner()),
                catchError((e) => {
                    this.spinnerService.hideSpinner();
                    return throwError(e);
                })
            )
            .subscribe((doctypes: AvailableDoctype[]) => {
                this.availableDoctypes = doctypes;
                this.selectedDoctype = this.getClientDoctype();

                this.initForm();
                this.modal = this.modalService.open(this.modalRef, { backdrop: 'static', keyboard: false, size: <any>'xl' });
            });
    }

    initForm() {
        if (this.isEdit) {
            this.pathRepository.getPath(this.docType.name, this.docType.version).subscribe((data) => {
                this.attributePathList = data;
            });
        }

        this.docTypesList = [];
        this.clientsHasDocType.forEach((client) => {
            client.docTypes.forEach((docType) => this.docTypesList.push(docType));
        });

        const clientId = this.isEdit ? this.docType.client : this.clientsList[0]._id;

        this.docTypeForm = new FormGroup({
            clientId: new FormControl(clientId, Validators.required),
            name: new FormControl(this.selectedDoctype.type, Validators.required),
            displayName: new FormControl(this.isEdit ? this.docType.displayName : '', Validators.required),
            version: new FormControl(this.selectedDoctype.versions[0], Validators.required),
            comment: new FormControl(this.isEdit ? this.docType.comment : ''),
            cloneAttributesDocTypeId: new FormControl(null)
        });

        if (this.isEdit) {
            this.docTypeForm.get('version').setValue(this.docType.version);
        }

        this.findClientByID(clientId);

        this.docTypeForm.get('clientId').valueChanges.subscribe((value) => {
            this.findClientByID(value);
        });
    }

    initAttributeForm(attribute, index) {
        this.attributeForm = new FormGroup({
            name: new FormControl(attribute.name, [Validators.required, this.checkUniqueAttributeName.bind(this), illegalCharacterInAttributeName, Validators.maxLength(30)]),
            description: new FormControl(attribute.description),
            path: new FormControl(attribute.path, [this.checkUniqueAttributePath.bind(this), this.illegalCharacterInAttributePath.bind(this)]),
            defaultReference: new FormControl(attribute.default ? attribute.default.reference : ''),
            onboardingComment: new FormControl(attribute.OnboardingComment),
            type: new FormControl(attribute.type, Validators.required),
            required: new FormControl(attribute.required),
            canStatic: new FormControl(attribute.canStatic),
            defaultStaticValue: new FormControl(attribute.default ? attribute.default.static : '', quotesValidation),
            hidden: new FormControl(attribute.hidden)
        });

        this.editingAttribute = attribute;
        this.editingAttributeIndex = index;

        if (this.attributeForm.get('hidden').value) {
            this.isHiddenField(true, attribute);
        }

        this.attributeForm
            .get('hidden')
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((val: boolean) => {
                this.isHiddenField(val, attribute);
            });

        this.attributeForm
            .get('canStatic')
            .valueChanges.pipe(takeUntil(this.unsubscribe))
            .subscribe((val: boolean) => {
                this.isCanStaticField(val);
            });
    }

    toggleShowHiddenAttributes() {
        this.showHiddenAttributes = !this.showHiddenAttributes;
    }

    countHidden() {
        const hiddenAttributes = this.docType.attributes.filter((a) => a.hidden);

        return hiddenAttributes && hiddenAttributes.length;
    }

    exitAttributeForm(save: boolean) {
        const attribute = { ...this.editingAttribute };

        if (save) {
            for (let key in this.attributeForm.controls) {
                this.attributeForm.controls[key].markAsTouched();
            }

            if (this.attributeForm.invalid) {
                return;
            }

            attribute.name = this.attributeForm.get('name').value;
            attribute.path = typeof this.attributeForm.get('path').value == 'string' ? this.attributeForm.get('path').value : this.attributeForm.get('path').value.path;
            attribute.default = {
                reference:
                    typeof this.attributeForm.get('defaultReference').value == 'string'
                        ? this.attributeForm.get('defaultReference').value
                        : this.attributeForm.get('defaultReference').value
                        ? this.attributeForm.get('defaultReference').value.path
                        : null,
                static: this.attributeForm.get('defaultStaticValue').value
            };
            attribute.type = this.attributeForm.get('type').value;
            attribute.required = this.attributeForm.get('required').value;
            attribute.canStatic = this.attributeForm.get('canStatic').value;
            attribute.hidden = this.attributeForm.get('hidden').value;
            attribute.defaultValue = this.attributeForm.get('defaultValue') ? this.attributeForm.get('defaultValue').value : null;

            if (!attribute.hidden) {
                attribute.defaultValue = null;
            }

            this.docType.attributes[this.editingAttributeIndex] = attribute;
        }

        this.editingAttribute = null;
        this.editingAttributeIndex = null;
    }

    private isHiddenField(val: boolean, attribute) {
        if (val) {
            this.attributeForm.addControl('defaultValue', new FormControl(attribute.defaultValue));
            this.attributeForm.get('canStatic').disable();
            this.attributeForm.get('required').disable();
        } else {
            this.attributeForm.removeControl('defaultValue');
            this.attributeForm.get('canStatic').enable();
            this.attributeForm.get('required').enable();
        }
        this.attributeForm.get('canStatic').setValue(false);
        this.attributeForm.get('required').setValue(false);
    }

    private isCanStaticField(val: boolean) {
        this.attributeForm.get('defaultReference').setValue(null);
        this.attributeForm.get('defaultStaticValue').setValue(null);
    }

    checkUniqueAttributeName(control: FormControl) {
        const otherAttributes = this.docType.attributes.filter((attr, index) => {
            return index !== this.editingAttributeIndex;
        });

        const duplicateNamesAttributesArr = otherAttributes.filter((attr) => {
            return control.value === attr.name;
        });

        return duplicateNamesAttributesArr.length > 0 ? { notUniqueName: true } : null;
    }

    checkUniqueAttributePath(control: FormControl) {
        const otherAttributes = this.docType.attributes.filter((attr, index) => {
            return index !== this.editingAttributeIndex;
        });

        const duplicatePathsAttributesArr = otherAttributes.filter((attr) => {
            return control.value === attr.path;
        });

        return duplicatePathsAttributesArr.length > 0 ? { notUniqueName: true } : null;
    }

    findClientByID(clientId: string) {
        if (clientId == null || clientId.length == 0) {
            this.selectedClient = null;
        }
        this.selectedClient = this.clientsList.filter((c) => {
            return c._id == clientId;
        })[0];
    }

    // Return doctype from available doctype list.
    // Checking does available doctype list has type and version form selected doctype.
    // If not we add new doctype with selected type and version to available doctype list.
    getClientDoctype(): AvailableDoctype {
        if (this.isEdit) {
            const dType = this.availableDoctypes.filter((d) => d.type == this.docType.name)[0];
            if (dType) {
                if (dType.versions.includes(this.docType.version)) {
                    return dType;
                }
                dType.versions = [...dType.versions, this.docType.version];
                return dType;
            } else {
                const newDType = { type: this.docType.name, versions: [this.docType.version] };
                this.availableDoctypes = [...this.availableDoctypes, newDType].sort();
                return newDType;
            }
        } else {
            return this.availableDoctypes[0];
        }
    }

    onSave() {
        for (let key in this.docTypeForm.controls) {
            this.docTypeForm.controls[key].markAsTouched();
        }
        if (this.docTypeForm.invalid) {
            return;
        }
        this.isEdit ? this.editDocType() : this.addDocType();
    }

    rowClass(attribute: DocTypeAttribute) {
        const path = attribute.path && attribute.path.toLowerCase();

        if (path.indexOf('special') === 0 || path.indexOf('items//special_') === 0) {
            return CSSClass.SpecialItem;
        }

        if (path.indexOf('items//') === 0) {
            return CSSClass.PositionItem;
        }

        return 'head-item';
    }

    onShowDeleteModal(attribute: DocTypeAttribute, index: number) {
        this.attributeToDelete = attribute;
        this.attributeToDeleteIndex = index;
        this.deleteModal = this.modalService.open(this.deleteModalRef);
    }

    private addDocType() {
        let client: ClientModel = this.findClientById(this.docTypeForm.get('clientId').value);
        let cloneAttributesDocType = this.docTypeForm.get('cloneAttributesDocTypeId').value;
        const docType: DoctypeModel = {
            name: this.docTypeForm.get('name').value,
            displayName: this.docTypeForm.get('displayName').value,
            version: this.docTypeForm.get('version').value,
            comment: this.docTypeForm.get('comment').value,
            active: true,
            client: this.docTypeForm.get('clientId').value,
            attributes: [],
            valid: false
        };
        // Clone attributes if exist
        if (cloneAttributesDocType && cloneAttributesDocType != '') {
            docType.attributes = this.docTypesList.filter((docType) => docType._id == cloneAttributesDocType)[0].attributes;
        }
        client.docTypes.push(docType);
        this.onSuccess.emit(client);
        this.modal.close();
    }

    private editDocType() {
        let client: ClientModel = this.findClientById(this.docTypeForm.get('clientId').value);
        let docType = client.docTypes.filter((docType) => {
            return docType._id === this.docType._id;
        })[0];
        docType.name = this.docTypeForm.get('name').value;
        docType.displayName = this.docTypeForm.get('displayName').value;
        docType.version = this.docTypeForm.get('version').value;
        docType.comment = this.docTypeForm.get('comment').value;
        docType.attributes = this.docType.attributes;
        this.modal.close();
        this.onSuccess.emit(client);
    }

    findClientById(clientId: string): ClientModel {
        return this.clientsList.filter((client) => client._id == clientId)[0];
    }

    pathAutocomplete(path$: Observable<any>) {
        const debouncedText$ = path$.pipe(debounceTime(200), distinctUntilChanged());
        return merge(debouncedText$, this.pathFocus$).pipe(
            map((p) => {
                return p == ''
                    ? this.attributePathList
                    : this.attributePathList.filter((path) => {
                          return path.path.toLowerCase().startsWith(p.toLowerCase());
                      });
            })
        );
    }

    defaultPathAutocomplete(path$: Observable<any>) {
        const debouncedText$ = path$.pipe(debounceTime(200), distinctUntilChanged());
        return merge(debouncedText$, this.defaultPathFocus$).pipe(
            map((p) => {
                return p == ''
                    ? this.attributePathList.filter((path) => {
                          return path.type == this.attributeForm.get('type').value;
                      })
                    : this.attributePathList.filter((path) => {
                          return path.type == this.attributeForm.get('type').value && path.path.toLowerCase().startsWith(p.toLowerCase());
                      });
            })
        );
    }

    pathFormatter = (path: AttributePath) => {
        if (typeof path == 'string') {
            return path;
        }
        return path.path;
    };

    selectedItem(item: any) {
        this.attributeForm.get('type').setValue(item.item.type);
    }

    ensureEndingSlash = (controlName: 'path' | 'defaultReference') => {
        const value = this.attributeForm.get(controlName).value;

        let path = value;

        if (value.path) {
            path = value.path;
        }

        if (value.defaultReference) {
            path = value.defaultReference;
        }

        path = path.replace(/\/*$/, '');

        if (path.toLowerCase() === 'items') {
            path += '/';
        }

        path += '/';

        this.attributeForm.get(controlName).setValue(path);
    };

    onAddAttribute(doctypeVersion: string) {
        this.addEditAttributeModal.onShowModal(this.docTypeForm.get('name').value, parseInt(doctypeVersion), this.docType.attributes);
    }

    onEditAttribute(attribute: DocTypeAttribute, doctypeVersion, index: number) {
        this.selectedAttribute = attribute;
        this.addEditAttributeModal.onShowModal(this.docTypeForm.get('name').value, doctypeVersion, this.docType.attributes, this.selectedAttribute, index);
    }

    onDeleteAttribute() {
        this.docType.attributes.splice(this.attributeToDeleteIndex, 1);
        this.deleteModal.close();
        this.attributeToDeleteIndex = null;
        this.attributeToDelete = null;
    }

    onAttributeModified(updateAttribute: OnUpdateAttributeEventData) {
        if (updateAttribute.index >= 0) {
            this.docType.attributes[updateAttribute.index] = updateAttribute.attribute;
        } else {
            this.docType.attributes.push(updateAttribute.attribute);
        }
        if (!this.correctReferenceOrder(this.docType.attributes)) {
            this.notificationService.notify('errors.attribute_referencing', 'warning');
        }
    }

    private correctReferenceOrder(attributes: DocTypeAttribute[]) {
        let inOrder = true;
        attributes.forEach(function (attribute) {
            if (attribute.default) {
                if (attribute.default.reference) {
                    attributes.forEach(function (attributeSecondary) {
                        if (attribute.default.reference == attributeSecondary.path && attributes.indexOf(attribute) < attributes.indexOf(attributeSecondary)) {
                            inOrder = false;
                        }
                    });
                }
            }
        });
        return inOrder;
    }

    onShowCloneDoctypeModal() {
        const selsectedClient = this.clientsList.filter((c) => {
            return c._id == this.docTypeForm.get('clientId').value;
        })[0];
        this.cloneDoctypeModal.onShowModal(selsectedClient);
    }

    onCloneDoctype(doctype: DoctypeModel) {
        this.docTypeForm.get('name').setValue(doctype.name);
        this.docTypeForm.get('displayName').setValue(doctype.displayName);
        this.docTypeForm.get('version').setValue(doctype.version);
        this.docTypeForm.get('comment').setValue('');
        this.docTypeForm.get('cloneAttributesDocTypeId').setValue(doctype._id);

        if (doctype.attributes && doctype.attributes.length > 0) {
            doctype.attributes.forEach((attr) => {
                attr.OnboardingComment = '';
            });
        }

        this.cloneDoctype = doctype;
    }

    onClearClone() {
        this.docTypeForm.get('name').setValue(this.selectedDoctype.type);
        this.docTypeForm.get('displayName').setValue('');
        this.docTypeForm.get('version').setValue(this.selectedDoctype.versions[0]);
        this.docTypeForm.get('comment').setValue('');
        this.docTypeForm.get('cloneAttributesDocTypeId').setValue('');
        this.cloneDoctype = null;
    }

    onSelectVersions(doctype: string) {
        this.selectedDoctype = this.availableDoctypes.filter((item: AvailableDoctype) => item.type == doctype)[0];
    }

    illegalCharacterInAttributePath(control: FormControl) {
        if (
            typeof control.value === 'string' &&
            !(control.value.toLowerCase() === 'items//') &&
            !(control.value.toLowerCase().indexOf('special_') === 0 || control.value.toLowerCase().indexOf('items//special_') === 0)
        ) {
            if (this.attributePathList && this.attributePathList.find((attrPath) => attrPath.path === control.value)) {
                return null;
            }
            return { illegalCharacter: true };
        }
        return null;
    }
}

function quotesValidation(control: FormControl) {
    if (!!control.value && (control.value.includes('"') || control.value.includes("'"))) {
        return { quotesValidation: true };
    }
    return null;
}

function illegalCharacterInAttributeName(control: FormControl) {
    if (control.value.includes('%')) {
        return { illegalCharacter: true };
    }
    return null;
}
