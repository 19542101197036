import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {ApiRoutesService} from "./api-routes.service";
import {AttributePath} from "../models/DoctypeModel";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class AttributePathDatabaseService {

  constructor(
    protected http: HttpClient,
    protected routesService: ApiRoutesService
  ) {

  }

  getPath(docType: string, verstion: number): Observable<AttributePath[]> {
    const data = {};
    if (!!docType && !!verstion) {
      data['version'] = verstion;
      data['doctype'] = docType;
    }
    return this.http.get<AttributePath[]>(this.routesService.PATH, {params: data}).pipe(map((data: any) => data.paths));
  }

}
