import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor(
    private translate: TranslateService,
  ) { }

  getClientMessage(error: Error): string {
    if (!navigator.onLine) {
      return this.translate.instant('errors.no_internet');
    }
    return error.message ? error.message : null;
  }

  getServerMessage(error: HttpErrorResponse): string {
    if (error.status === 401) {
      return this.translate.instant('errors.reauthenticate');
    }
    return error.error.dictionaryKey;
  }

}
