import {BrowserModule} from '@angular/platform-browser';
import {NgModule, ErrorHandler} from '@angular/core';
import {HttpClient, HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

import {TranslateModule, TranslateLoader} from '@ngx-translate/core';

import {NgbModule} from '@ng-bootstrap/ng-bootstrap';

import {SnackbarModule} from 'ngx-snackbar';

import {AppRoutingModule} from './app-routing.module';

import {AppComponent} from './app.component';

import {Components} from './components';

import {AuthInterceptor} from './interceptors/auth.interceptor';

import {Services} from './services';
import {ApiRoutesService} from './services/api-routes.service';
import {GlobalErrorHandler} from './services/global-error-handler.service';
import {HttpMultiLoaderService} from './services/http-multi-loader-service.service';
import {environment} from '../environments/environment';
import {Pipes} from './pipes';
import {TooltipModule} from 'ngx-bootstrap';
import {PrettyJsonModule} from 'angular2-prettyjson';
import {SortablejsModule} from 'ngx-sortablejs';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorLogService } from './services/error-log.service';

export const createTranslateLoader = (http: HttpClient, apiRoutesService: ApiRoutesService, errorService: ErrorLogService) => {
  return new HttpMultiLoaderService(http, [
    {prefix: './assets/i18n', suffix: '.json'},
    {prefix: `${apiRoutesService.I18N}`},
  ], errorService);
};

@NgModule({
  declarations: [
    AppComponent,
    ...Components,
    ...Pipes,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient, ApiRoutesService]
      }
    }),
    NgbModule,
    PrettyJsonModule,
    SortablejsModule.forRoot({ animation: 150 }),
    SnackbarModule.forRoot(),
    TooltipModule.forRoot(),
    BrowserAnimationsModule
  ],
  providers: [
    HttpClientModule,
    ...Services,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: GlobalErrorHandler
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
