import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import {FormGroup, FormControl, Validators} from '@angular/forms';
import {Router} from '@angular/router';

import {noop, of, Subject, throwError} from 'rxjs';
import {catchError, takeUntil} from 'rxjs/operators';
import {AuthService} from '../../services/auth.service';
import {ApiRoutesService} from '../../services/api-routes.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnDestroy, OnInit {

  private unsubscribe: Subject<void> = new Subject();
  contentElement: Element;
  invalidPasswordCount = 0;
  hubLink = '';

  constructor(
    private authService: AuthService,
    private apiRoutes: ApiRoutesService,
    private router: Router,
    protected rd: Renderer2
  ) {
    this.hubLink = `${this.apiRoutes.HUB_LOGIN}?target=admin`;
  }

  ngOnDestroy(): void {
    if (this.contentElement) {
      this.rd.removeClass(this.contentElement, 'login');
    }
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  ngOnInit(): void {
    if (document.body.getElementsByClassName('content').length > 0) {
      this.contentElement = document.body.getElementsByClassName('content')[0];
      this.rd.addClass(this.contentElement, 'login');
    }
  }

}
