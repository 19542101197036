import {Injectable} from "@angular/core";
import {Observable} from "rxjs";
import {AddNewMailTemplateModel, MailTemplateModel} from "../models/MailTemplateModel";
import {HttpClient} from "@angular/common/http";
import {ApiRoutesService} from "./api-routes.service";
import {IHttpRepository} from "./IHttpRepository";

@Injectable({
  providedIn: 'root'
})
export class MailTemplateService implements IHttpRepository<MailTemplateModel> {

  constructor(
    protected http: HttpClient,
    protected apiRoutesService: ApiRoutesService) {
  }

  getList(sortBy?: string, isAsc?: boolean): Observable<MailTemplateModel[]> {
    let options = {};
    if (!!sortBy && isAsc != undefined) {
      options = {
        params: {
          sortBy: sortBy,
          order: (isAsc) ? 'asc' : 'desc'
        }
      }
    }
    return this.http.get<MailTemplateModel[]>(this.apiRoutesService.MAIL_TEMPLATES, options);
  }

  add(item: AddNewMailTemplateModel): Observable<MailTemplateModel> {
    return this.http.post<MailTemplateModel>(this.apiRoutesService.MAIL_TEMPLATES, item);
  }

  delete(id: string): Observable<any> {
    return undefined;
  }

  findById(id: string): Observable<MailTemplateModel> {
    return undefined;
  }

  update(id: string, model: MailTemplateModel): Observable<MailTemplateModel> {
    return this.http.patch<MailTemplateModel>(this.apiRoutesService.MAIL_TEMPLATES + '/' + model._id, model);
  }

}
